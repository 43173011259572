import { Chip, ChipOwnProps } from "@mui/material";

export type StatusChipType = 'CommitmentStatusTypeName' | 'WorkflowStageName' | 'StatusTypeName' | 'ActionStatusName' | 'StatusName'

const statusChipMap: Record<StatusChipType, Record<string, Pick<ChipOwnProps, 'color' | 'size' | 'label'>>> = {
    CommitmentStatusTypeName: {
        "Action Not Required": {
            color: "info"
        },
        "Action Not Yet Required": {
            color: "info"
        },
        "Not Started": {
            color: "error"
        },
        "In Progress": {
            color: "info"
        },
        "Ongoing - Commitment Being Met": {
            color: "info"
        },
        "Ongoing - Commitment Not Being Met": {
            color: "warning"
        },
        "Completed": {
            color: "success"
        },
        "Complete - Commitment Met": {
            color: "success"
        },
        "Complete - Commitment Not Met": {
            color: "success"
        },
        "Complete - Not Required": {
            color: "success"
        },
        "Closed - Commitment Not Met": {
            color: "secondary"
        },
        "Closed - Commitment Met": {
            color: "secondary"
        }
    },
    WorkflowStageName: {
        "In Progress": {
            color: "info"
        },
        "Submitted for Review": {
            color: "warning"
        },
        "Complete": {
            color: "success"
        },
        "Referred Back": {
            color: "error"
        }
    },
    StatusTypeName: {
        "Scheduled": {
            color: "info"
        },
        "In Progress": {
            color: "info"
        },
        "Completed": {
            color: "success"
        },
        "Overdue": {
            color: "error"
        },
        "Cancelled": {
            color: "error"
        }
    },
    ActionStatusName: {
        "Not Started": {
            color: "error"
        },
        "In Progress": {
            color: "info"
        },
        "Closed Completed": {
            color: "success"
        },
        "Closed Incomplete": {
            color: "warning"
        },
        "No Longer Needed": {
            color: "info"
        }
    },
    StatusName: {
        "On Hold": {
            color: "info"
        },
        "Not Started": {
            color: "warning"
        },
        "Ongoing": {
            color: "warning"
        },
        "Completed": {
            color: "success"
        },
        "Draft": {
            color: "warning"
        },
        "Submitted": {
            color: "success"
        },
        "Rejected": {
            color: "error"
        }
    }
}

interface StatusChipCellProps {
    type: StatusChipType;
    value: string;
}

const StatusChipCell = ({ type, value }: StatusChipCellProps) => {
    const { color = 'info', label = value, size = 'small' } = statusChipMap[type][value] ?? {}

    return <Chip label={label} size={size} color={color} />
}

export default StatusChipCell;