import { IppTextField } from "components/IppTextField";

export const getNumField = (props: any) => {
  const { id, label, formik, disabled, toolTip, customChangeHandler, value } =
    props;

  // Handle nested values (e.g., "Onshore.ManagementMale")
  const getNestedValue = (path: string, obj: any) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  // Get the value directly from the value prop if provided, otherwise from formik values
  const fieldValue =
    value !== undefined ? value : getNestedValue(id, formik.values);

  // Ensure the value is always a number, defaulting to 0 if undefined, null, or empty string
  const displayValue =
    fieldValue !== undefined && fieldValue !== null && fieldValue !== ""
      ? fieldValue.toString()
      : "0";

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Ensure the value is always a number
    const newValue = e.target.value === "" ? "0" : e.target.value;

    // For flat fields (no dots in id), set directly
    if (!id.includes(".")) {
      formik.setFieldValue(id, Number(newValue));
    } else {
      // For nested fields, use the nested path
      formik.setFieldValue(id, Number(newValue));
    }

    // Call custom change handler if provided
    if (customChangeHandler) {
      customChangeHandler(e);
    }
  };

  return (
    <IppTextField
      id={id}
      label={label || ""}
      value={displayValue}
      onChangeFunction={handleChange}
      touchedExpression={
        getNestedValue(id, formik.touched) || getNestedValue(id, formik.errors)
      }
      errorsExpression={getNestedValue(id, formik.errors)}
      fieldType="number"
      disabled={disabled}
      toolTip={toolTip}
      isEditing={false}
      setIsEditing={() => {}}
    />
  );
};

export const getTotalField = ({
  id,
  label,
  formik,
  toolTip,
  value,
}: {
  id: string;
  label: string;
  formik: any;
  toolTip?: string;
  value?: number;
}) => {
  // Handle nested values (e.g., "Onshore.ManagementTotal")
  const getNestedValue = (path: string, obj: any) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return getNumField({
    id,
    label,
    formik,
    disabled: true, // Total fields are read-only
    toolTip,
    value: value !== undefined ? value : getNestedValue(id, formik.values) || 0,
  });
};

export const TotalFields = [
  {
    id: "Onshore.ManagementTotal",
    FieldIds: [
      "Onshore.ManagementMale",
      "Onshore.ManagementFemale",
      "Onshore.ManagementOther",
    ],
  },
  {
    id: "Onshore.AdministrationTotal",
    FieldIds: [
      "Onshore.AdministrationMale",
      "Onshore.AdministrationFemale",
      "Onshore.AdministrationOther",
    ],
  },
  {
    id: "Onshore.EngineeringTotal",
    FieldIds: [
      "Onshore.EngineeringMale",
      "Onshore.EngineeringFemale",
      "Onshore.EngineeringOther",
    ],
  },
  {
    id: "Onshore.TechniciansTotal",
    FieldIds: [
      "Onshore.TechniciansMale",
      "Onshore.TechniciansFemale",
      "Onshore.TechniciansOther",
    ],
  },
  {
    id: "Onshore.ProfessionalsTotal",
    FieldIds: [
      "Onshore.ProfessionalsMale",
      "Onshore.ProfessionalsFemale",
      "Onshore.ProfessionalsOther",
    ],
  },
  {
    id: "Onshore.SkilledTradesTotal",
    FieldIds: [
      "Onshore.SkilledTradesMale",
      "Onshore.SkilledTradesFemale",
      "Onshore.SkilledTradesOther",
    ],
  },
  {
    id: "Onshore.LabourTotal",
    FieldIds: [
      "Onshore.LabourMale",
      "Onshore.LabourFemale",
      "Onshore.LabourOther",
    ],
  },
  {
    id: "Onshore.StudentsTotal",
    FieldIds: [
      "Onshore.StudentsMale",
      "Onshore.StudentsFemale",
      "Onshore.StudentsOther",
    ],
  },
  {
    id: "Onshore.OtherTotal",
    FieldIds: [
      "Onshore.OtherMale",
      "Onshore.OtherFemale",
      "Onshore.OtherOther",
    ],
  },

  // Offshore totals
  {
    id: "Offshore.ManagementTotal",
    FieldIds: [
      "Offshore.ManagementMale",
      "Offshore.ManagementFemale",
      "Offshore.ManagementOther",
    ],
  },
  {
    id: "Offshore.AdministrationTotal",
    FieldIds: [
      "Offshore.AdministrationMale",
      "Offshore.AdministrationFemale",
      "Offshore.AdministrationOther",
    ],
  },
  {
    id: "Offshore.EngineeringTotal",
    FieldIds: [
      "Offshore.EngineeringMale",
      "Offshore.EngineeringFemale",
      "Offshore.EngineeringOther",
    ],
  },
  {
    id: "Offshore.TechniciansTotal",
    FieldIds: [
      "Offshore.TechniciansMale",
      "Offshore.TechniciansFemale",
      "Offshore.TechniciansOther",
    ],
  },
  {
    id: "Offshore.ProfessionalsTotal",
    FieldIds: [
      "Offshore.ProfessionalsMale",
      "Offshore.ProfessionalsFemale",
      "Offshore.ProfessionalsOther",
    ],
  },
  {
    id: "Offshore.SkilledTradesTotal",
    FieldIds: [
      "Offshore.SkilledTradesMale",
      "Offshore.SkilledTradesFemale",
      "Offshore.SkilledTradesOther",
    ],
  },
  {
    id: "Offshore.LabourTotal",
    FieldIds: [
      "Offshore.LabourMale",
      "Offshore.LabourFemale",
      "Offshore.LabourOther",
    ],
  },
  {
    id: "Offshore.StudentsTotal",
    FieldIds: [
      "Offshore.StudentsMale",
      "Offshore.StudentsFemale",
      "Offshore.StudentsOther",
    ],
  },
  {
    id: "Offshore.OtherTotal",
    FieldIds: [
      "Offshore.OtherMale",
      "Offshore.OtherFemale",
      "Offshore.OtherOther",
    ],
  },

  // Overall total headcount fields
  {
    id: "Onshore.TotalHeadcount",
    FieldIds: [
      "Onshore.ManagementTotal",
      "Onshore.AdministrationTotal",
      "Onshore.EngineeringTotal",
      "Onshore.TechniciansTotal",
      "Onshore.ProfessionalsTotal",
      "Onshore.SkilledTradesTotal",
      "Onshore.LabourTotal",
      "Onshore.StudentsTotal",
      "Onshore.OtherTotal",
    ],
  },
  {
    id: "Offshore.TotalHeadcount",
    FieldIds: [
      "Offshore.ManagementTotal",
      "Offshore.AdministrationTotal",
      "Offshore.EngineeringTotal",
      "Offshore.TechniciansTotal",
      "Offshore.ProfessionalsTotal",
      "Offshore.SkilledTradesTotal",
      "Offshore.LabourTotal",
      "Offshore.StudentsTotal",
      "Offshore.OtherTotal",
    ],
  },
  // Expenditure totals by category
  {
    id: "TotalMaterials",
    FieldIds: ["MaterialsNL", "MaterialsOC", "MaterialsNC"],
  },
  {
    id: "TotalLabour",
    FieldIds: ["LabourNL", "LabourOC", "LabourNC"],
  },
  {
    id: "TotalServices",
    FieldIds: ["ServicesNL", "ServicesOC", "ServicesNC"],
  },
  {
    id: "TotalTransportation",
    FieldIds: ["TransportationNL", "TransportationOC", "TransportationNC"],
  },
  {
    id: "TotalOverheads",
    FieldIds: ["OverheadsNL", "OverheadsOC", "OverheadsNC"],
  },

  // Regional expenditure totals
  {
    id: "TotalExpenditureNL",
    FieldIds: [
      "MaterialsNL",
      "LabourNL",
      "ServicesNL",
      "TransportationNL",
      "OverheadsNL",
    ],
  },
  {
    id: "TotalExpenditureOC",
    FieldIds: [
      "MaterialsOC",
      "LabourOC",
      "ServicesOC",
      "TransportationOC",
      "OverheadsOC",
    ],
  },
  {
    id: "TotalExpenditureNC",
    FieldIds: [
      "MaterialsNC",
      "LabourNC",
      "ServicesNC",
      "TransportationNC",
      "OverheadsNC",
    ],
  },

  // Grand total expenditure across all categories and regions
  {
    id: "TotalExpenditureOverall",
    FieldIds: [
      "TotalMaterials",
      "TotalLabour",
      "TotalServices",
      "TotalTransportation",
      "TotalOverheads",
    ],
  },
];

export const EXPENDITURE_FIELDS_NL = [
  "MaterialsNL",
  "LabourNL",
  "ServicesNL",
  "TransportationNL",
  "OverheadsNL",
];

export const EXPENDITURE_FIELDS_OC = [
  "MaterialsOC",
  "LabourOC",
  "ServicesOC",
  "TransportationOC",
  "OverheadsOC",
];

export const EXPENDITURE_FIELDS_NC = [
  "MaterialsNC",
  "LabourNC",
  "ServicesNC",
  "TransportationNC",
  "OverheadsNC",
];

export const LocationTypeList = ["Onshore", "Offshore"];

export const ContractManagerList = [
  "Janine Dicks",
  "Kashmala Rehman",
  "Daniel Stoyles",
];

export interface NestedTotalField {
  id: string;
  FieldIds: string[];
}

export const getNestedTotalField = ({
  id,
  label,
  formik,
  toolTip,
}: {
  id: string;
  label: string;
  formik: any;
  toolTip?: string;
}) => {
  // Handle nested values (e.g., "Onshore.ManagementMale")
  const getNestedValue = (path: string, obj: any) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return getNumField({
    id,
    label,
    formik,
    disabled: true,
    toolTip,
    value: getNestedValue(id, formik.values) || 0,
  });
};

// Generate headcount fields for the questionnaire
export const generateHeadcountFields = () => {
  const locations = ["Onshore", "Offshore"] as const;
  const categories = [
    "Management",
    "Administration",
    "Engineering",
    "Technicians",
    "Professionals",
    "SkilledTrades",
    "Labour",
    "Students",
    "Other",
  ] as const;
  const genders = ["Male", "Female", "Other"] as const;
  const subLocations = ["NL", "OC", "NC"] as const;

  const fields: string[] = [];

  for (const location of locations) {
    for (const category of categories) {
      for (const gender of genders) {
        for (const subLocation of subLocations) {
          fields.push(`${location}.${category}.${gender}.${subLocation}`);
        }
      }
    }
  }

  // Optionally include general headcount fields (if you're validating them)
  fields.push("HeadcountCategory");
  fields.push("HeadcountProvince");

  return fields;
};

export const transformHeadcountToNested = (
  headcount: any[]
): { Onshore: any; Offshore: any } => {
  const result: any = {
    Onshore: {},
    Offshore: {},
  };

  headcount.forEach((entry) => {
    const location = entry.LocationType; // "Onshore" or "Offshore"
    const category = entry.CategoryName;

    if (!result[location][category]) {
      result[location][category] = {
        Male: { NL: 0, OC: 0, NC: 0 },
        Female: { NL: 0, OC: 0, NC: 0 },
        Other: { NL: 0, OC: 0, NC: 0 },
      };
    }

    result[location][category].Male.NL = entry.NLMale ?? 0;
    result[location][category].Male.OC = entry.OCMale ?? 0;
    result[location][category].Male.NC = entry.NCMale ?? 0;

    result[location][category].Female.NL = entry.NLFemale ?? 0;
    result[location][category].Female.OC = entry.OCFemale ?? 0;
    result[location][category].Female.NC = entry.NCFemale ?? 0;

    result[location][category].Other.NL = entry.NLOther ?? 0;
    result[location][category].Other.OC = entry.OCOther ?? 0;
    result[location][category].Other.NC = entry.NCOther ?? 0;
  });

  return result;
};
