import { Tooltip, useTheme, Box } from "@mui/material";

interface CommaDelimitedCellProps {
    value: string;
}

const CommaDelimitedCell = ({ value }: CommaDelimitedCellProps) => {
    const theme = useTheme()
    const groups = (value as string | null)?.split(',')

    if (!groups) {
        return <span>{value}</span>
    }

    return (
        <Tooltip title={value} placement="top" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <span style={{ 
                    flex: '1 1 auto',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    minWidth: 0
                }}>{groups[0]}</span>
                {(groups.length > 1) && (
                    <span style={{ color: theme.palette.secondary.main, marginLeft: theme.spacing(), flexShrink: 0 }}>
                        +{groups.length - 1}
                    </span>
                )}
            </Box>
        </Tooltip>
    )
}

export default CommaDelimitedCell