import axiosClient from "./axiosClient"

export async function getInventoryMetadata(accessToken: string, inventoryName: string) {
    const url = `/inventory/metadata/${inventoryName}`

    const { data } = await axiosClient.get(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })

    return data;
}