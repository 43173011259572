import axiosClient from "../axiosClient";
import { transformHeadcountToNested } from "features/benefitsQuestionnaire/FormHelpers";

export interface BenefitsQuestionnaire {
  Status?: string;
  CorrectionReason?: string;
  ReferenceNumber?: string;
  BenefitsQuestionnaireID: number;
  accessKey?: string;
  TermsAccepted: boolean; // Made required to align with QuestionnaireFormValues
  ProponentName?: string;
  ProponentAddress?: string;
  ProponentCity?: string;
  ProponentProvinceState?: string;
  ProponentCountry?: string;
  WorkScopeAddress?: string;
  WorkScopeCity?: string;
  WorkScopeProvinceState?: string;
  WorkScopeCountry?: string;
  ExpectedGoodsServices?: string;
  ExpectedAnticipatedWorkLocation?: string;
  ExistingGoodsServices?: string;
  ExistingAnticipatedWorkLocation?: string;
  ExistingSubconSupName?: string;
  ExistingSubconSupLocation?: string;
  PercentNLandOC?: number;
  HeadcountCategory?: string;
  HeadcountProvince?: string;
  name?: string;
  email?: string;
  MaterialsNL?: number;
  MaterialsOC?: number;
  MaterialsNC?: number;
  LabourNL?: number;
  LabourOC?: number;
  LabourNC?: number;
  ServicesNL?: number;
  ServicesOC?: number;
  ServicesNC?: number;
  TransportationNL?: number;
  TransportationOC?: number;
  TransportationNC?: number;
  OverheadsNL?: number;
  OverheadsOC?: number;
  OverheadsNC?: number;
  TotalContentNL?: number;
  TotalContentOC?: number;
  TotalContentNC?: number;
  IsSubmitted?: boolean;
  Onshore?: {
    Management?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Administration?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Engineering?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Technicians?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Professionals?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    SkilledTrades?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Labour?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Students?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Other?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    TotalHeadcount?: number;
    TotalPersonHours?: number;
  };
  Offshore?: {
    Management?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Administration?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Engineering?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Technicians?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Professionals?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    SkilledTrades?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Labour?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Students?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    Other?: {
      Male?: number;
      Female?: number;
      Other?: number;
    };
    TotalHeadcount?: number;
    TotalPersonHours?: number;
  };
}

export interface BenefitsQuestionnaireResult {
  benefitsQuestionnaires: BenefitsQuestionnaire[];
}

export async function getAllBenefitsQuestionnaire(
  accessToken: string
): Promise<BenefitsQuestionnaireResult> {
  const url = `/benefitsQuestionnaire`;

  try {
    const { data } = await axiosClient.get<BenefitsQuestionnaireResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // Apply headcount transformation to each questionnaire
    data.benefitsQuestionnaires.forEach((q: any) => {
      if (q.headcount) {
        const nested = transformHeadcountToNested(q.headcount);
        q.Onshore = nested.Onshore;
        q.Offshore = nested.Offshore;
      }
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getQuestionnaireById(
  questionnaireID: number | null
): Promise<BenefitsQuestionnaire> {
  const url = `/benefitsQuestionnaire/${questionnaireID}`;

  try {
    const { data } = await axiosClient.get<BenefitsQuestionnaire>(url);

    if ((data as any).headcount) {
      const nested = transformHeadcountToNested((data as any).headcount);
      data.Onshore = nested.Onshore;
      data.Offshore = nested.Offshore;
    }

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateQuestionnaire(
  questionnaireID: number,
  questionnaireData: Partial<
    Pick<BenefitsQuestionnaire, "Status" | "CorrectionReason">
  >,
  accessToken?: string
): Promise<BenefitsQuestionnaire> {
  const url = `/benefitsQuestionnaire/${questionnaireID}`;

  try {
    const { data } = await axiosClient.post<BenefitsQuestionnaire>(
      url,
      questionnaireData,
      accessToken
        ? {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        : undefined
    );
    return data;
  } catch (err: any) {
    throw err;
  }
}
