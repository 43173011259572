import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { IppStepper } from "../../components/IppStepper";
import { QuestionnaireFormValues } from "./BenefitsQuestionnaireTypes";
import { stepConfigs, getSteps } from "./BenefitsQuestionnaireStepConfig";
import { ArrowBack, ArrowForward, Work } from "@mui/icons-material";
import { IppQuestionnaireButton } from "../../components/IppQuestionnaireButton";
import { useNavigate } from "react-router-dom-v5-compat";
import { updateQuestionnaire } from "api/benefitsQuestionnaire/benefitsQuestionnaireAPI";
import { IppTextField } from "../../components/IppTextField";
import { IppButton } from "components/Buttons/IppButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { updBenefitsQuestionnaire } from "./benefitsQuestionnaireSlice";
import { IppInfoBar } from "components/IppInfoBar";
import { FieldData } from "components/Reusable Utils/RenderDisplayField";

//------------------------------FORM SETUP----------------------------
const QuestionnaireForm = (props: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [correctionReason, setCorrectionReason] = useState("");
  const steps = getSteps();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const handleCustomChange =
    (section: "Onshore" | "Offshore") =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = event.target;
      const fieldName = id.split(".")[1];
      formik.setFieldValue(
        `${section}.${fieldName}`,
        value === "" ? "0" : value
      );
    };

  const questionnaireData = props.benefitsQuestionnaire;
  const questionnaireID = questionnaireData.BenefitsQuestionnaireID;
  const accessKey = questionnaireData.AccessKey;

  const handleSubmit = async () => {
    try {
      if (!questionnaireID) {
        console.error("No Questionnaire ID available for submission.");
        return;
      }

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      dispatch(
        updBenefitsQuestionnaire(
          accessToken,
          questionnaireID,
          {
            Status: "Reviewed",
          },
          true // setReturnRoute: will redirect using redux-first-history
        )
      );

      navigate("/questionnaire");
    } catch (error) {
      console.error("Error submitting questionnaire:", error);
    }
  };

  const formik = useFormik<QuestionnaireFormValues>({
    initialValues: {
      ...questionnaireData, // Ensure provided data overrides defaults
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSend = async () => {
    try {
      const questionnaireID = questionnaireData?.BenefitsQuestionnaireID;
      if (!questionnaireID) {
        console.error("No Questionnaire ID available for corrections.");
        return;
      }

      if (!correctionReason) {
        alert("Please provide a reason for returning the form.");
        return;
      }

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      dispatch(
        updBenefitsQuestionnaire(
          accessToken,
          questionnaireID,
          {
            Status: "Returned",
            CorrectionReason: correctionReason,
          },
          true // setReturnRoute: will redirect using redux-first-history
        )
      );

      setOpenDialog(false);
    } catch (error) {
      console.error("Error returning for corrections:", error);
    }
  };

  const handleSendAccessKey = () => {};

  const renderStepForm = () => {
    const StepComponent = stepConfigs[activeStep].component;
    return (
      <StepComponent formik={formik} handleCustomChange={handleCustomChange} />
    );
  };

  useEffect(() => {
    if (questionnaireData) {
      setIsLoading(false);
    }
  }, [questionnaireData]);

  const getStatusChip = (status: string) => {
    return <Typography variant="caption">{status}</Typography>; // Replace with real <Chip /> logic if needed
  };

  const infoBarFieldData: FieldData[] = [
    {
      key: "Status",
      label: "Status",
      value: questionnaireData.Status || "Draft",
      fieldType: "chip",
      statusChip: getStatusChip,
      gridSettings: { xs: 12, md: 3 },
    },
    {
      key: "ReferenceNumber",
      label: "Reference #",
      value: questionnaireData.ReferenceNumber || "N/A",
      fieldType: "text",
      gridSettings: { xs: 12, md: 3 },
    },
    {
      key: "ProponentName",
      label: "Proponent Name",
      value: questionnaireData.ProponentName || "N/A",
      fieldType: "text",
      gridSettings: { xs: 12, md: 6 },
    },
  ];

  return (
    <Box sx={{ width: "80%", mx: "auto", position: "relative" }}>
      <Paper sx={{ p: 3 }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 4 }}>
              <IppInfoBar
                title="Benefits Questionnaire"
                value={`Scope of Work: Hibernia Drill Supply`}
                icon={<Work />}
                fieldData={infoBarFieldData}
                onEdit={() => ""}
              />
            </Box>
            <IppButton
              color="primary"
              onClick={handleSendAccessKey}
              sx={{ position: "absolute", right: 16 }}
            >
              Resend Access Key
            </IppButton>
            <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
              {stepConfigs[activeStep].label}
            </Typography>
            <IppStepper
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              viewOnly={true}
            />
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mt={2} paddingX={2}>
                {renderStepForm()}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Start (Back button) */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {activeStep > 0 && (
                    <IppQuestionnaireButton
                      label={"Back"}
                      styling={"start"}
                      icon={<ArrowBack />}
                      onClick={handleBack}
                    />
                  )}
                </Box>

                {/* Center (Return for Corrections button) */}
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                >
                  <IppQuestionnaireButton
                    label={"Return for Corrections"}
                    onClick={handleDialogOpen}
                    styling={"centered"}
                    color={"error"}
                  />
                </Box>

                {/* End (Next / Mark Reviewed button) */}
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
                >
                  {activeStep < steps.length - 1 ? (
                    <IppQuestionnaireButton
                      icon={<ArrowForward />}
                      label={"Next"}
                      onClick={handleNext}
                      styling={"end"}
                      iconPosition="end" // Ensure icon comes after the label
                    />
                  ) : (
                    <IppQuestionnaireButton
                      label={"Mark Reviewed"}
                      onClick={handleSubmit}
                      styling={"end"}
                      icon={<ArrowForward />}
                    />
                  )}
                </Box>
              </Box>
            </form>
          </>
        )}
      </Paper>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Return for Corrections</DialogTitle>
        <DialogContent sx={{ overflowX: "hidden", paddingX: 3 }}>
          <Typography sx={{ mb: 2 }}>
            Please provide a reason for returning this form for corrections.
          </Typography>
          <IppTextField
            id="CorrectionReason"
            label="Correction Reason"
            value={correctionReason}
            required
            onChangeFunction={(e: any) => setCorrectionReason(e.target.value)}
            touchedExpression={!correctionReason}
            errorsExpression={!correctionReason ? "Required" : ""}
            isEditing={true}
            setIsEditing={() => {}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleSend}
            disabled={!correctionReason.trim()}
          >
            Return for Corrections
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionnaireForm;
