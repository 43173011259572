import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchAllBenefitsQuestionnaire } from "./benefitsQuestionnaireSlice";
import { useTranslation } from "react-i18next";
import BenefitsQuestionnaireForm from "./BenefitsQuestionnaireForm";

interface BenefitsQuestionnaireProps {
  questionnaireID: string;
}

export const BenefitsQuestionnaireDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const { isLoading, error, benefitsQuestionnaireByID } = useSelector(
    (state: RootState) => state.benefitsQuestionnaire
  );

  // get benefits questionnaire ID from url param
  const { questionnaireID } = useParams<BenefitsQuestionnaireProps>();
  const itemID = parseInt(questionnaireID, 10); // invalid param will return NaN

  const paramErr = `${t(
    "strGen:errors.wentwrong"
  )} - ${questionnaireID} is not a valid Benefits Questionnaire ID`;

  const benefitsQuestionnaire =
    benefitsQuestionnaireByID[parseInt(questionnaireID, 10)];

  // fetch benefits questionnaires
  useEffect(() => {
    (async () => {
      try {
        // can avoid going to the DB if we navigated from inventory
        if (!benefitsQuestionnaire?.ProponentName) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchAllBenefitsQuestionnaire(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  let benefitsQuestionnaireDetailView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      {/* param is invalid */}
      {Number.isNaN(itemID) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
      {/* get benefits questionnaires error */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>{t("strGen:errors.wentwrong")}</b> - {error.toString()}
          </span>
        </Alert>
      )}

      {/* display benefits questionnaire if exists */}
      {benefitsQuestionnaire ? (
        <div>
          <BenefitsQuestionnaireForm
            benefitsQuestionnaire={benefitsQuestionnaire}
            questionnaireID={questionnaireID}
          />
        </div>
      ) : (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>{paramErr}</span>
        </Alert>
      )}
    </div>
  );

  return (
    <div id="benefits-questionnaire-detail-page">
      {benefitsQuestionnaireDetailView}
    </div>
  );
};
