import { useCallback, useMemo, useState } from "react";
import {
  Box,
  ButtonGroup,
  Divider,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IppEditButton } from "./Buttons/IppEditButton";
import { IppButton } from "./Buttons/IppButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTypedTranslation } from "utils/customHooks";

export interface IppInforBarButtonGroupAction {
  key: string;
  label: string;
  icon?: React.ReactNode;
  onClick: () => void;
}

interface IppInforBarButtonGroupProps {
  actions: IppInforBarButtonGroupAction[];
  onEdit: () => void;
  inReviewRecord?: boolean;
}

export const IppInforBarButtonGroup = ({
  actions,
  onEdit,
  inReviewRecord = false,
}: IppInforBarButtonGroupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const t = useTypedTranslation(["strGen"]);

  const buttons = [
    { key: "EditButton", label: "Edit", onClick: onEdit },
    ...actions,
  ];

  const buttonSx = {
    backgroundColor: "white",
    "&:hover": { backgroundColor: "#f0f0f0" },
    "&:focus": { backgroundColor: "#e0e0e0" },
  };

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  );

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  // Responsive breakpoints
  const isSmallerThan900 = useMediaQuery(theme.breakpoints.down(900));
  const isSmallerThan1050 = useMediaQuery(theme.breakpoints.down(1050));
  const isSmallerThan1150 = useMediaQuery(theme.breakpoints.down(1150));

  // Determine visible and overflow actions based on screen size
  const visibleCount = useMemo(() => {
    if (isSmallerThan900) return inReviewRecord ? 0 : 1;
    if (isSmallerThan1050) return inReviewRecord ? 1 : 2;
    if (isSmallerThan1150) return inReviewRecord ? 2 : 3;
    return 3;
  }, [isSmallerThan900, isSmallerThan1050, isSmallerThan1150, inReviewRecord]);

  const visibleActions = buttons.slice(0, visibleCount);
  const overflowActions = buttons.slice(visibleCount);

  // Dropdown Menu
  const overflowActionsMenu = (
    <>
      <IppButton
        onClick={handleMenuOpen}
        variant="outlined"
        sx={buttonSx}
        disableTime={0}
      >
        <ArrowDropDownIcon />
      </IppButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {overflowActions.map((action, index) => (
          <Box key={action.key}>
            <MenuItem onClick={action.onClick} dense>
              {action.icon && <Box marginRight={1}>{action.icon}</Box>}
              {action.label}
            </MenuItem>
            {/* Add Divider except after the last MenuItem */}
            {index < overflowActions.length - 1 && <Divider />}
          </Box>
        ))}
      </Menu>
    </>
  );

  return (
    <Box display="flex" alignItems="center" marginLeft={5}>
      {inReviewRecord && (
        <IppEditButton
          buttonText={t("strGen:buttons.review")}
          variant="contained"
          color="warning"
          showStartIcon={false}
          onClick={onEdit}
          sx={{
            mr: 2,
          }}
        />
      )}

      {/* Button Group for Visible Actions */}
      <ButtonGroup
        variant="outlined"
        size="small"
        sx={{ "& .MuiButton-root": { mr: 0, ...buttonSx } }}
      >
        {visibleActions.length > 0 &&
          visibleActions.map((action, index) => (
            <IppButton key={index} onClick={action.onClick} variant="outlined">
              {action.label}
            </IppButton>
          ))}
        {overflowActions.length > 0 && overflowActionsMenu}
      </ButtonGroup>
    </Box>
  );
};
