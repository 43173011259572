import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { IppDisplayChip } from "components/IppDisplayChip";
import { IppDisplayField } from "components/IppDisplayField";
import { IppDisplayRichText } from "components/IppDisplayRichText";

export type FieldData =
  | {
      key: string;
      label: string;
      value: string | number | null;
      fieldType: "text";
      gridSettings?: GridSizeConfig;
    }
  | {
      key: string;
      label: string;
      value: string | number | null;
      fieldType: "chip";
      statusChip: (status: string) => JSX.Element;
      gridSettings?: GridSizeConfig;
    }
  | {
      key: string;
      label: string;
      value: boolean;
      fieldType: "checkbox";
      gridSettings?: GridSizeConfig;
    }
  | {
      key: string;
      label: string;
      value: string | null;
      fieldType: "richtext";
      gridSettings?: GridSizeConfig;
    };

type GridSizeConfig = Partial<Record<"xs" | "sm" | "md" | "lg" | "xl", number>>;

export const RenderDisplayField = (field: FieldData) => {
  switch (field.fieldType) {
    case "chip":
      return (
        <IppDisplayChip
          label={field.label}
          value={field.value}
          isEditing={false}
          setIsEditing={null}
          showEdit={false}
          showFooter={false}
          statusChip={field.statusChip}
        />
      );
    case "checkbox":
      return (
        <IppDisplayCheckbox
          label={field.label}
          isChecked={field.value}
          isEditing={false}
          setIsEditing={null}
          showEdit={false}
        />
      );
    case "richtext":
      return (
        <IppDisplayRichText
          label={field.label}
          value={field.value}
          isEditing={false}
          setIsEditing={null}
          showEdit={false}
        />
      );
    case "text":
    default:
      return (
        <IppDisplayField
          label={field.label}
          value={field.value}
          isEditing={false}
          setIsEditing={null}
          showEdit={false}
          showFooter={false}
        />
      );
  }
};
