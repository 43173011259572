import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
import {
  getFormSubmissionById,
  createFormSubmission,
  updateFormSubmission,
} from "api/stakeholder/campaigns/scopeOfWorkAPI";
import {
  CampaignFormSubmission,
  CreateCampaignFormSubmission,
  UpdateCampaignFormSubmission,
} from "types/stakeholder/campaigns/ScopeOfWork.types";

interface SowFormSubmissionState {
  sowFormSubmission: CampaignFormSubmission | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: SowFormSubmissionState = {
  sowFormSubmission: null,
  isLoading: false,
  error: null,
};

function startLoading(state: SowFormSubmissionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: SowFormSubmissionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const sowFormSubmissionSlice = createSlice({
  name: "sowFormSubmission",
  initialState,
  reducers: {
    fetchSowFormSubmissionStart: startLoading,
    fetchSowFormSubmissionSuccess(
      state,
      { payload }: PayloadAction<CampaignFormSubmission>
    ) {
      state.isLoading = false;
      state.error = null;
      state.sowFormSubmission = payload;
    },
    fetchSowFormSubmissionFailure: loadingFailed,
    createSowFormSubmissionStart: startLoading,
    createSowFormSubmissionSuccess(
      state,
      { payload }: PayloadAction<CampaignFormSubmission>
    ) {
      state.isLoading = false;
      state.error = null;
      state.sowFormSubmission = payload;
    },
    createSowFormSubmissionFailure: loadingFailed,
    updateSowFormSubmissionStart: startLoading,
    updateSowFormSubmissionSuccess(
      state,
      { payload }: PayloadAction<CampaignFormSubmission>
    ) {
      state.isLoading = false;
      state.error = null;
      state.sowFormSubmission = payload;
    },
    updateSowFormSubmissionFailure: loadingFailed,
    clearSowFormSubmission(state) {
      state.sowFormSubmission = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  fetchSowFormSubmissionStart,
  fetchSowFormSubmissionSuccess,
  fetchSowFormSubmissionFailure,
  createSowFormSubmissionStart,
  createSowFormSubmissionSuccess,
  createSowFormSubmissionFailure,
  updateSowFormSubmissionStart,
  updateSowFormSubmissionSuccess,
  updateSowFormSubmissionFailure,
  clearSowFormSubmission,
} = sowFormSubmissionSlice.actions;

export default sowFormSubmissionSlice.reducer;

// Thunks
export const fetchSowFormSubmissionById =
  (accessToken: string, sowFormSubmissionId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchSowFormSubmissionStart());
      const sowFormSubmission = await getFormSubmissionById(
        accessToken,
        sowFormSubmissionId
      );
      dispatch(fetchSowFormSubmissionSuccess(sowFormSubmission));
    } catch (err: any) {
      dispatch(fetchSowFormSubmissionFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const createSowFormSubmission =
  (
    accessToken: string,
    formDefinitionId: number,
    formData: CreateCampaignFormSubmission
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSowFormSubmissionStart());
      const sowFormSubmission = await createFormSubmission(
        accessToken,
        formDefinitionId,
        formData
      );
      dispatch(createSowFormSubmissionSuccess(sowFormSubmission));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createSowFormSubmissionFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updateSowFormSubmission =
  (
    accessToken: string,
    formSubmissionId: number,
    formData: UpdateCampaignFormSubmission
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateSowFormSubmissionStart());
      const sowFormSubmission = await updateFormSubmission(
        accessToken,
        formSubmissionId,
        formData
      );
      dispatch(updateSowFormSubmissionSuccess(sowFormSubmission));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(updateSowFormSubmissionFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
