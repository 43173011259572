import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Commitment } from "api/commitments/commitmentAPI";
import { getInventoryMetadata } from "api/inventoryMetadata";
import { Interaction } from "api/stakeholder/interactionAPI";
import { AppThunk } from "app/store";
import { IconKey } from "components/Inventory/columnDefs";
import { priorityIcons, interationTypeIcons, sentimentIcons } from "./icons";
import { StatusChipType } from "components/Inventory/inventoryCells/StatusChipCell";
import { deepMerge } from "utils/functions";

export interface ColumnMetadata<T = object> {
    // Title of the column header
    title: string;
    // Order of the column
    order: number;
    // Column type (used for filtering/sorting)
    type: string;
    // Is the column disabled?
    disabled?: boolean;
    // Is the column hidden? Can be hidden/displayed in column menu
    visible?: boolean;
    // Column width
    width?: number;
    // Primary id to idenitfy unique data rows. Atleast one column must have primary enabled
    primary?: true;
    // Format of the column data. Currently does a token replace with "{value}" as the only token. For example, format: "ID-{value}" would display the string "ID-1" for a row with value "1"
    format?: string;
    // Creates a relative link where the path is the value of the link property. For example, if link: "CommitmentID", then this will create a relative link "/1234" for a row with a "CommitmentID" value of "1234"
    link?: keyof T;
    // Status chip type. Render chips based on the statusChip value
    statusChip?: StatusChipType;
    // Map cell values to icon keys
    icon?: Record<string | number, IconKey | IconMetadata>;
    // Render the first item from a comma delimited string and displays the remaining item count
    commaDelimited?: boolean;
}

export interface IconMetadata {
    id: IconKey;
    tooltipText?: string;
}

interface InventoryMetadata {
    isLoading: boolean;
    error: string | null;
    metadata: ColumnMetadata | null;
}

interface InventoryMetadataState {
    metadataByName: Record<string, InventoryMetadata>
}

const initialState: InventoryMetadataState = {
    metadataByName: {}
}

interface DefaultMetadataMap {
    'commitments.commitments': Partial<Record<keyof Commitment, ColumnMetadata<Commitment>>>;
    'engagement.communications': Partial<Record<keyof Interaction, ColumnMetadata<Interaction>>>;
}

const defaultMetadata: DefaultMetadataMap = {
    'commitments.commitments': {
        SequenceID: {
            title: 'ID',
            order: 0,
            type: 'string',
            format: 'CMT-{value}',
            link: 'CommitmentID',
            primary: true
        },
        PriorityID: {
            title: 'Priority',
            order: 1,
            type: 'number',
            icon: priorityIcons
        },
        CommitmentTitle: {
            title: 'Commitment',
            order: 2,
            type: 'string',
            link: 'CommitmentID'
        },
        CommitmentCategoryName: {
            title: 'Category',
            order: 3,
            type: 'string'
        },
        RecordedDate: {
            title: 'Date',
            order: 4,
            type: 'date'
        },
        ProjectName: {
            title: 'Project',
            order: 5,
            type: 'string'
        },
        CommitmentReference: {
            title: 'Reference',
            order: 6,
            type: 'string'
        },
        PersonResponsibleName: {
            title: 'Person Responsible',
            order: 7,
            type: 'string'
        },
        ActionCount: {
            title: 'To-Do Actions',
            order: 8,
            type: 'number'
        },
        CommitmentStatusTypeID: {
            title: 'Status',
            order: 9,
            type: 'string',
            statusChip: 'CommitmentStatusTypeName'
        }
    },
    'engagement.communications': {
        CommunicationID: {
            title: 'ID',
            order: 1,
            type: 'string',
            format: 'C-{value}',
            link: 'InteractionID',
            width: 80,
            primary: true
        },
        InteractionTitle: {
            title: 'Title',
            order: 2,
            type: 'string',
            link: 'InteractionID',
        },
        InteractionTypeName: {
            title: 'Type',
            order: 3,
            type: 'string',
            width: 60,
            icon: interationTypeIcons
        },
        InteractionDate: {
            title: 'Date',
            order: 4,
            type: 'date',
            width: 110
        },
        SentimentLevel: {
            title: 'Sentiment',
            order: 5,
            type: 'number',
            width: 100,
            icon: sentimentIcons
        },
        Contacts: {
            title: 'Contacts',
            order: 6,
            type: 'string',
            commaDelimited: true
        },
        Groups: {
            title: 'Groups',
            order: 7,
            type: 'string',
            commaDelimited: true
        },
        ProjectName: {
            title: 'Project',
            order: 8,
            type: 'string'
        },
        PersonResponsibleName: {
            title: 'Person Responsible',
            order: 9,
            type: 'string',
            width: 175
        },
        ProjectCode: {
            title: 'Project Code',
            order: 10,
            type: 'string'
        }
  }
}

const inventoryMetadata = createSlice({
    name: "inventoryMetadata",
    initialState,
    reducers: {
        getInventoryMetadataStart: (state: InventoryMetadataState, { payload: inventoryName }: PayloadAction<string>) => {
            if (!state.metadataByName[inventoryName]) {
                state.metadataByName[inventoryName] = {
                    isLoading: false,
                    error: null,
                    metadata: null
                }
            }

            state.metadataByName[inventoryName].isLoading = true;
        },

        getInventoryMetadataSuccess: (state: InventoryMetadataState, { payload }: PayloadAction<{ inventoryName: string, metadata: any}>) => {
            const { inventoryName, metadata } = payload;
            
            if (!state.metadataByName[inventoryName]) {
                state.metadataByName[inventoryName] = {
                    isLoading: false,
                    error: null,
                    metadata: null
                }
            }

            state.metadataByName[inventoryName].isLoading = false;
            const metadataKeys = Object.keys(metadata.Metadata ?? {}).filter(key => !metadata.Metadata[key].disabled)

            // @ts-ignore
            const mergedMetadata = Object.keys(defaultMetadata[inventoryName as keyof DefaultMetadataMap] ?? {}).reduce((acc, key) => {
                if (metadataKeys.includes(key)) {
                    // @ts-ignore
                    acc[key] = deepMerge(
                        // @ts-ignore
                        defaultMetadata[inventoryName as keyof DefaultMetadataMap]?.[key] ?? {}, 
                        metadata.Metadata[key]
                    );
                } else {
                    // @ts-ignore
                    acc[key] = {
                        // @ts-ignore
                        ...defaultMetadata[inventoryName]?.[key],
                        visible: false
                    }
                }

                return acc
            }, {})
            // @ts-ignore
            state.metadataByName[inventoryName].metadata = metadataKeys.length > 0 ? mergedMetadata : defaultMetadata[inventoryName];
        },

        getInventoryMetadataFailure: (state: InventoryMetadataState, { payload }: PayloadAction<{ inventoryName: string, error: string }>) => {
            const { inventoryName, error } = payload
            
            if (!state.metadataByName[inventoryName]) {
                state.metadataByName[inventoryName] = {
                    isLoading: false,
                    error: null,
                    metadata: null
                }
            }

            state.metadataByName[inventoryName].isLoading = false;
            state.metadataByName[inventoryName].error = error;
        },

        updateInventoryMetadata: (state: InventoryMetadataState, { payload }: PayloadAction<{ inventoryName: string, metadata: ColumnMetadata }>) => {
            const { inventoryName, metadata } = payload

            // @ts-ignore
            state.metadataByName[inventoryName].metadata = metadata;
        }
    }
})

export const { getInventoryMetadataStart, getInventoryMetadataSuccess, getInventoryMetadataFailure, updateInventoryMetadata: updateLocalInventoryMetadata } = inventoryMetadata.actions;

export default inventoryMetadata.reducer;

export const fetchInventoryMetadata = (accessToken: string, inventoryName: string): AppThunk => async (dispatch) => {
    try {
        dispatch(getInventoryMetadataStart(inventoryName))
        dispatch(getInventoryMetadataSuccess({
            inventoryName,
            metadata: await getInventoryMetadata(accessToken, inventoryName)
        }))
    } catch (error: unknown) {
        dispatch(getInventoryMetadataFailure({
            inventoryName,
            error: (error as Error).toString()
        }))
    }
}

export const updateInventoryMetadata = (inventoryName: string, metadata: ColumnMetadata): AppThunk => async (dispatch) => {
    dispatch(updateLocalInventoryMetadata({
        inventoryName,
        metadata
    }))
}