import { TooltipProps, Tooltip } from "@mui/material";
import { ReactElement, FC } from "react";
import { useTypedTranslation } from "utils/customHooks";

interface IconCellProps {
    icon: ReactElement;
    tooltipText?: string;
    tooltipPlacement?: TooltipProps["placement"];
}

const IconCell: FC<IconCellProps> = ({ tooltipText, icon, tooltipPlacement = "top" }) => {
    const t = useTypedTranslation(["strGen"])

    return (
        <Tooltip
            title={tooltipText ? t(tooltipText) : undefined}
            placement={tooltipPlacement}
            arrow
        >
            {icon}
         </Tooltip>
    )
}

export default IconCell;