import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BenefitsQuestionnaire,
  BenefitsQuestionnaireResult,
  getAllBenefitsQuestionnaire,
  updateQuestionnaire,
} from "api/benefitsQuestionnaire/benefitsQuestionnaireAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import * as Constants from "utils/snackBarConstants";

interface BenefitsQuestionnaireState {
  benefitsQuestionnaireByID: Record<number, BenefitsQuestionnaire>;
  benefitsQuestionnaireList: number[];
  isLoading: boolean;
  error: string | null;
}

const initialState: BenefitsQuestionnaireState = {
  benefitsQuestionnaireByID: {},
  benefitsQuestionnaireList: [],
  isLoading: false,
  error: null,
};

const startLoading = (state: BenefitsQuestionnaireState) => {
  state.isLoading = true;
};

const loadingFailed = (
  state: BenefitsQuestionnaireState,
  action: PayloadAction<string>
) => {
  state.isLoading = false;
  state.error = action.payload;
};

const benefitsQuestionnaire = createSlice({
  name: "benefitsQuestionnaire",
  initialState,
  reducers: {
    getBenefitsQuestionnaireStart: startLoading,
    getBenefitsQuestionnaireSuccess(
      state,
      { payload }: PayloadAction<BenefitsQuestionnaireResult>
    ) {
      const { benefitsQuestionnaires } = payload;
      state.isLoading = false;
      state.error = null;
      benefitsQuestionnaires.forEach((item) => {
        state.benefitsQuestionnaireByID[item.BenefitsQuestionnaireID!] = item;
      });
      state.benefitsQuestionnaireList = benefitsQuestionnaires.map(
        (item) => item.BenefitsQuestionnaireID!
      );
    },
    getBenefitsQuestionnaireFailure: loadingFailed,
    updateBenefitsQuestionnaireStart: startLoading,
    updateBenefitsQuestionnaireSuccess(
      state,
      { payload }: PayloadAction<BenefitsQuestionnaire>
    ) {
      const { BenefitsQuestionnaireID } = payload;
      state.benefitsQuestionnaireByID[BenefitsQuestionnaireID] = payload;
      state.isLoading = false;
      state.error = null;
    },
    updateBenefitsQuestionnaireFailure: loadingFailed,
  },
});

export const {
  getBenefitsQuestionnaireStart,
  getBenefitsQuestionnaireSuccess,
  getBenefitsQuestionnaireFailure,
  updateBenefitsQuestionnaireStart,
  updateBenefitsQuestionnaireSuccess,
  updateBenefitsQuestionnaireFailure,
} = benefitsQuestionnaire.actions;

export default benefitsQuestionnaire.reducer;

export const fetchAllBenefitsQuestionnaire =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getBenefitsQuestionnaireStart());
      const result = await getAllBenefitsQuestionnaire(accessToken);
      dispatch(getBenefitsQuestionnaireSuccess(result));
    } catch (err: any) {
      dispatch(getBenefitsQuestionnaireFailure(err.toString()));
    }
  };

export const updBenefitsQuestionnaire =
  (
    accessToken: string,
    questionnaireID: number,
    updatedQuestionnaire: Partial<BenefitsQuestionnaire>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateBenefitsQuestionnaireStart());
      const questionnaire = await updateQuestionnaire(
        questionnaireID,
        updatedQuestionnaire,
        accessToken // ✅ pass token here
      );
      dispatch(updateBenefitsQuestionnaireSuccess(questionnaire));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/questionnaire/questionnaires`));
      }
    } catch (err: any) {
      dispatch(updateBenefitsQuestionnaireFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
