import axiosClient from "../axiosClient";

export interface Commitment {
  CommitmentID: number;
  SequenceID: number;
  CommitmentTitle: string;
  CommitmentDetails: string;
  RecordedDate: Date;
  CommitmentStatusTypeID: number;
  CommitmentSourceTypeID: number;
  CommitmentSourceID: number;
  CommitmentReference: string;
  CommitmentCategoryID: number;
  ProjectID: number;
  PersonResponsible: number;
  OverallResponsibility: string;
  GroupID: number;
  CommitmentNotes: string;
  ClientID: number;
  ActionCount: number;
  CommitmentStatusTypeName: string;
  CommitmentSourceTypeName: string;
  CommitmentSourceName: string;
  ProjectName: string;
  CommitmentCategoryName: string;
  PersonResponsibleName: string;
  LegalText: string;
  ContactID: number | null;
  AssociatedContactID: number | null;
  AmendmentID?: number;
  InternalNotes: string;
  Tags: string;
  StatusChangeDate: Date;
  CreateDate?: Date;
  CreatedBy?: number;
  LastUpdated?: Date;
  LastUpdatedBy?: number;
  CompletedDate: Date;
  PriorityID?: number;
}

export interface CommitmentsResult {
  commitments: Commitment[];
}

export async function getCommitments(
  accessToken: String
): Promise<CommitmentsResult> {
  const url = `/commitment`;

  try {
    const { data } = await axiosClient.get<CommitmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentsByGroup(
  accessToken: String,
  groupID: number
): Promise<CommitmentsResult> {
  const url = `/commitment/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<CommitmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentsByAssociatedContact(
  accessToken: String,
  contactID: number
): Promise<CommitmentsResult> {
  const url = `/commitment/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<CommitmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentsBySource(
  accessToken: String,
  commitmentSourceID: number
): Promise<CommitmentsResult> {
  const url = `/commitment/source/${commitmentSourceID}`;

  try {
    const { data } = await axiosClient.get<CommitmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitment(
  accessToken: String,
  itemData: Partial<Commitment>,
  phases?: any[],
  groupList?: string[]
): Promise<Commitment> {
  const url = `/commitment`;

  try {
    const { data } = await axiosClient.post<Commitment>(
      url,
      { itemData, groupList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitment(
  accessToken: String,
  commInvID: number,
  itemData: Partial<Commitment>,
  groupList?: string[],
  phases?: any[]
): Promise<Commitment> {
  const url = `/commitment/` + commInvID;

  try {
    const { data } = await axiosClient.post<Commitment>(
      url,
      { itemData, groupList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitment(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/commitment/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
