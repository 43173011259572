import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
import { getFormDefinitionById } from "api/stakeholder/campaigns/scopeOfWorkAPI";
import { CampaignFormDefinition } from "types/index.types";

interface SowFormDefinitionState {
  sowFormDefinition: CampaignFormDefinition | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: SowFormDefinitionState = {
  sowFormDefinition: null,
  isLoading: false,
  error: null,
};

function startLoading(state: SowFormDefinitionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: SowFormDefinitionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const sowFormDefinitionSlice = createSlice({
  name: "sowFormDefinition",
  initialState,
  reducers: {
    fetchSowFormDefinitionStart: startLoading,
    fetchSowFormDefinitionSuccess(
      state,
      { payload }: PayloadAction<CampaignFormDefinition>
    ) {
      state.isLoading = false;
      state.error = null;
      state.sowFormDefinition = payload;
    },
    fetchSowFormDefinitionFailure: loadingFailed,
    clearSowFormDefinition(state) {
      state.sowFormDefinition = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  fetchSowFormDefinitionStart,
  fetchSowFormDefinitionSuccess,
  fetchSowFormDefinitionFailure,
  clearSowFormDefinition,
} = sowFormDefinitionSlice.actions;

export default sowFormDefinitionSlice.reducer;

// Thunks
export const fetchSowFormDefinitionById =
  (accessToken: string, sowFormDefinitionId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchSowFormDefinitionStart());
      const sowFormDefinition = await getFormDefinitionById(
        accessToken,
        sowFormDefinitionId
      );
      dispatch(fetchSowFormDefinitionSuccess(sowFormDefinition));
    } catch (err: any) {
      dispatch(fetchSowFormDefinitionFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
