import { GridColDef } from "@mui/x-data-grid";
import LinkCell from "../inventoryCells/LinkCell";

export const getLinkCellColumnDef = <T,>(key: keyof T): Omit<GridColDef, 'field'> => {
    return {
        type: 'string',
        renderCell: (cell) => {
            return <LinkCell text={cell.formattedValue} path={cell.row[key]}/>
        }
    }
}