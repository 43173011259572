import { QuestionnaireStep } from "./BenefitsQuestionnaireSteps";
import {
  LegalAcknowledgments,
  ProponentInfo,
  SubContractorSupplier,
  ExpenditureContentEstimate,
  BenefitsCommitments,
} from "./BenefitsQuestionnaireEditSteps";
import HeadcountTable from "./HeadountTable";
import { generateHeadcountFields } from "./FormHelpers";

export interface StepConfig {
  id: string;
  label: string;
  component: React.ComponentType<any>;
  validationSchema: any;
  fields: string[];
}

export const stepConfigs: StepConfig[] = [
  {
    id: "legalAcknowledgments",
    label: "Legal Acknowledgments",
    component: LegalAcknowledgments,
    validationSchema: null,
    fields: ["TermsAccepted"],
  },
  {
    id: "proponentInfo",
    label: "Proponent Info",
    component: ProponentInfo,
    validationSchema: null,
    fields: [
      "ProponentName",
      "ProponentAddress",
      "ProponentCity",
      "ProponentProvinceState",
      "ProponentCountry",
      "WorkScopeAddress",
      "WorkScopeCity",
      "WorkScopeProvinceState",
      "WorkScopeCountry",
    ],
  },
  {
    id: "subContractorSupplier",
    label: "Subcontractor & Supplier Information",
    component: SubContractorSupplier,
    validationSchema: null,
    fields: [
      "ExpectedGoodsServices",
      "ExpectedAnticipatedWorkLocation",
      "ExistingGoodsServices",
      "ExistingAnticipatedWorkLocation",
      "ExistingSubconSupName",
      "ExistingSubconSupLocation",
      "PercentNLandOC",
    ],
  },
  {
    id: "headcount",
    label: "Headcount and Person Hour Estimate",
    component: HeadcountTable,
    validationSchema: null,
    fields: generateHeadcountFields(),
  },
  {
    id: "expenditureContentEstimate",
    label: "Expenditure Content Estimate",
    component: ExpenditureContentEstimate,
    validationSchema: null,
    fields: [
      "TotalEstimatedCost",
      "MaterialsNL",
      "MaterialsOC",
      "MaterialsNC",
      "LabourNL",
      "LabourOC",
      "LabourNC",
      "ServicesNL",
      "ServicesOC",
      "ServicesNC",
      "TransportationNL",
      "TransportationOC",
      "TransportationNC",
      "OverheadsNL",
      "OverheadsOC",
      "OverheadsNC",
      "TotalExpenditureNL",
      "TotalExpenditureOC",
      "TotalExpenditureNC",
      "TotalExpenditureOverall",
    ],
  },
  {
    id: "benefitsCommitments",
    label: "Benefits Commitments",
    component: BenefitsCommitments,
    validationSchema: null,
    fields: [
      "ProcurementStrategy",
      "Policies",
      "ResearchDevelopment",
      "TechTransfer",
    ],
  },
];

export const getSteps = (): QuestionnaireStep[] => {
  return stepConfigs.map((config) => ({
    id: config.id,
    label: config.label,
    fields: config.fields,
    error: false,
  }));
};
