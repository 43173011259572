import axios from "axios";

const basePath = process.env.REACT_APP_API;
const pathArray = window.location.pathname.split("/");
const clientShortName = pathArray[1];
// console.log("TENURL", clientShortName);

const axiosClient = axios.create({
  baseURL: `${basePath}/${clientShortName}/api`,
});

export default axiosClient;

export const getAuthHeaders = (token: string, isWrite = false) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...(isWrite && { "Content-Type": "application/json" }),
  },
});
