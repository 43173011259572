import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Grid,
  alpha,
  useTheme,
} from "@mui/material";
import {
  IppInforBarButtonGroup,
  IppInforBarButtonGroupAction,
} from "./IppInfoBarButtonGroup";
import {
  FieldData,
  RenderDisplayField,
} from "./Reusable Utils/RenderDisplayField";

interface IppInfoBarProps {
  title: string;
  value: string;
  icon: React.ReactNode;
  actions?: IppInforBarButtonGroupAction[];
  onEdit: () => void;
  fieldData: FieldData[];
  inReviewRecord?: boolean;
}

export const IppInfoBar = ({
  title,
  value,
  icon,
  actions,
  onEdit,
  fieldData,
  inReviewRecord = false,
}: IppInfoBarProps) => {
  const theme = useTheme();

  const backgroundColor = inReviewRecord
    ? theme.palette.warning.light
    : alpha(theme.palette.secondary.main, 0.3);

  const buttonGroup = actions ? (
    <IppInforBarButtonGroup
      actions={actions}
      onEdit={onEdit}
      inReviewRecord={inReviewRecord}
    />
  ) : null;

  return (
    <Card sx={{ width: "100%", borderRadius: 2, overflow: "hidden" }}>
      {/* Header Section */}
      <CardHeader
        sx={{
          backgroundColor: backgroundColor,
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        title={
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                borderRadius: 2,
                width: 48,
                height: 48,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </Box>
            <Box display="flex" flexDirection="column" gap={0}>
              <Typography variant="caption">{title}</Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {value}
              </Typography>
            </Box>
          </Box>
        }
        action={buttonGroup}
      />

      {/* Content Section */}
      <CardContent
        sx={{
          backgroundColor: "white",
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          {fieldData.map((field) => (
            <Grid item key={field.key} {...field.gridSettings}>
              {RenderDisplayField(field)}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

// SAMEPLE USE CASE (Interactions)
//-- CAN BE REMOVED ONCE WE START TO IMPLEMENT THIS COMPONENT

//NOTE: The onClick functions are just placeholders and should be replaced with actual functions

/* 
  const infoBarActions: IppInforBarButtonGroupAction[] = [
    ...(itemData.StatusTypeID !== 3 && itemData.StatusTypeID !== 2
      ? [
          {
            key: "CompleteButton",
            label: "Mark as Complete",
            onClick: () => console.log("Update Status clicked"),
          },
        ]
      : []),
    {
      key: "NewActionButton",
      label: "New Action",
      onClick: () => console.log("New Action clicked"),
    },
    {
      key: "AddAttachmentsButton",
      label: "Add Attachments",
      onClick: () => console.log("Add Attachments clicked"),
    },
    {
      key: "AddCommentButton",
      label: "Add Comment",
      onClick: () => console.log("Add Comment clicked"),
    },
    {
      key: "EditContactsButton",
      label: "Edit Contacts",
      onClick: () => console.log("Edit Contacts clicked"),
    },
    {
      key: "EditGroupsButton",
      label: "Edit Groups",
      onClick: () => console.log("Edit Groups clicked"),
    },
    {
      key: "EditTopicsButton",
      label: "Edit Topics",
      onClick: () => console.log("Edit Topics clicked"),
    },
    {
      key: "LinkConcernsButton",
      label: "Link Concerns",
      onClick: () => console.log("Link Concerns clicked"),
    },
    {
      key: "LinkThreadsButton",
      label: "Link Thread",
      onClick: () => console.log("Link Thread clicked"),
    },
    {
      key: "LinkCommitmentButton",
      label: "Link Commitment",
      onClick: () => console.log("Link Commitment clicked"),
    },
    {
      key: "DeleteButton",
      label: "Delete",
      onClick: () => console.log("Delete clicked"),
    },
  ];

  const infoBarFieldData: FieldData[] = [
    {
      key: "InteractionProject",
      label: t("objStk:objects.interaction.fields.project"),
      value: itemData.ProjectName,
      fieldType: "text",
      gridSettings: { xs: 4, md: 3, lg: 2 },
    },
    {
      key: "InteractionDate",
      label: t("objStk:objects.interaction.fields.date"),
      value: ConvertToJSDate(itemData.InteractionDate),
      fieldType: "text",
      gridSettings: { xs: 4, md: 2 },
    },
    {
      key: "InteractionPersonResponsible",
      label: t("objStk:objects.interaction.fields.personresponsible"),
      value: itemData.PersonResponsibleName,
      fieldType: "text",
      gridSettings: { xs: 4, md: 3, lg: 2 },
    },
    {
      key: "InteractionTypeName",
      label: t("objStk:objects.interaction.fields.type"),
      value: itemData.InteractionTypeName,
      fieldType: "text",
      gridSettings: { xs: 4, md: 2 },
    },
    {
      key: "InteractionStatus",
      label: t("objStk:objects.interaction.fields.status"),
      value: itemData.StatusTypeName,
      fieldType: "chip",
      statusChip: getCommunicationStatusChips,
      gridSettings: { xs: 4, md: 2 },
    },
  ];

  THIS PART WILL BE USED IN THE RENDER PART OF THE FORM COMPONENT
  
          <IppInfoBar
          title={t("objStk:objects.interaction.name")}
          value={`C-${itemData.CommunicationID} ${itemData.InteractionTitle}`}
          icon={<ChatIcon />}
          fieldData={infoBarFieldData}
          actions={infoBarActions}
          onEdit={() => setIsEditing(true)}
          inReviewRecord={itemData.NeedsReview}
        />
*/
