import { AlternateEmailOutlined, ComputerOutlined, ConnectWithoutContactOutlined, Diversity3Outlined, DragHandle, EmailOutlined, EventOutlined, GroupOutlined, HandshakeOutlined, HelpOutline, HomeOutlined, KeyboardArrowDown, KeyboardArrowUp, LanguageOutlined, MarkunreadMailboxOutlined, PhoneIphoneOutlined, PhoneOutlined, Public, RadioOutlined, SentimentDissatisfiedTwoTone, SentimentNeutralTwoTone, SentimentSatisfiedTwoTone, SentimentVeryDissatisfiedTwoTone, SentimentVerySatisfiedTwoTone } from "@mui/icons-material"
import { GridColDef } from "@mui/x-data-grid"
import IconCell from "../inventoryCells/IconCell";
import { Box } from "@mui/material";
import { ReactElement } from "react";
import { ColumnMetadata, IconMetadata } from "slices";

interface IconDetails {
    icon: ReactElement;
    tooltipText?: string;
}

export type IconKey = 'priority:low' | 'priority:medium' | 'priority:high' | 'sentiment:very_dissatisfied' | 'sentiment:dissatisfied' | 'sentiment:neutral' | 'sentiment:satisfied' | 'sentiment:very_satisfied' | 'interaction:email' | 'interaction:phone_call' | 'interaction:meeting_in_person' | 'interaction:meeting_virtual' | 'interaction:regular_mail' | 'interaction:event' | 'interaction:text_message' | 'interaction:registered_mail' | 'interaction:location_based_mail_out' | 'interaction:media' | 'interaction:open_house' | 'interaction:web_platform' | 'interaction:social_media' | 'interaction:focus_group' | 'interaction:cultural_event' | 'interaction:informal_encounter' | 'interaction:sponsorship' | 'interaction:undefined';

const ICON_INFO_MAP: Record<IconKey, IconDetails> = {
    "priority:low": {
        icon: <KeyboardArrowDown fontSize="small" color="info" />,
        tooltipText: "strGen:components.inventoryicons.getpriorityicons.low"
    },
    "priority:medium": {
        icon: <DragHandle fontSize="small" color="warning" />,
        tooltipText: "strGen:components.inventoryicons.getpriorityicons.medium"
    },
    "priority:high": {
        icon: <KeyboardArrowUp fontSize="small" color="error" />,
        tooltipText: "strGen:components.inventoryicons.getpriorityicons.high"
    },
    'sentiment:very_dissatisfied': {
        icon: <SentimentVeryDissatisfiedTwoTone color="error" />,
        tooltipText: "strGen:components.inventoryicons.getsentimenticons.verydissatisfied"
    },
    'sentiment:dissatisfied': {
        icon: <SentimentDissatisfiedTwoTone color="error" />,
        tooltipText: "strGen:components.inventoryicons.getsentimenticons.dissatisfied"
    },
    'sentiment:neutral': {
        icon: <SentimentNeutralTwoTone color="warning" />,
        tooltipText: "strGen:components.inventoryicons.getsentimenticons.neutral"
    },
    'sentiment:satisfied': {
        icon: <SentimentSatisfiedTwoTone color="info" />,
        tooltipText: "strGen:components.inventoryicons.getsentimenticons.satisfied"
    },
    'sentiment:very_satisfied': {
        icon: <SentimentVerySatisfiedTwoTone color="success" />,
        tooltipText: "strGen:components.inventoryicons.getsentimenticons.verysatisfied"
    },
    'interaction:email': {
        icon: <AlternateEmailOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.email"
    },
    'interaction:phone_call': {
        icon: <PhoneOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.phonecall"
    },
    'interaction:meeting_in_person': {
        icon: <GroupOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.mettinginperson"
    },
    'interaction:meeting_virtual': {
        icon: <ComputerOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.meetingvirtual"
    },
    'interaction:regular_mail': {
        icon: <EmailOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.regularmail"
    },
    'interaction:event': {
        icon: <EventOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.event"
    },
    'interaction:text_message': {
        icon: <PhoneIphoneOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.textmessage"
    },
    'interaction:registered_mail': {
        icon: <MarkunreadMailboxOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.registeredmail"
    },
    'interaction:location_based_mail_out': {
        icon: <Public fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.locationbasedmailout"
    },
    'interaction:media': {
        icon: <RadioOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.media"
    },
    'interaction:open_house': {
        icon: <HomeOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.openhouse"
    },
    'interaction:web_platform': {
        icon: <LanguageOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.webplatform"
    },
    'interaction:social_media': {
        icon: <ConnectWithoutContactOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.socialmedia"
    },
    'interaction:focus_group': {
        icon: <Diversity3Outlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.focusgroup"
    },
    'interaction:cultural_event': {
        icon: <EventOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.culturalevent"
    },
    'interaction:informal_encounter': {
        icon: <GroupOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.informalencounter"
    },
    'interaction:sponsorship': {
        icon: <HandshakeOutlined fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.sponsorship"
    },
    'interaction:undefined': {
        icon: <HelpOutline fontSize="small" />,
        tooltipText: "strGen:components.inventoryicons.getinteractiontypeicons.other"
    }
};

export const getIconColumnDef = (iconMetadata: NonNullable<ColumnMetadata["icon"]>): Omit<GridColDef, 'field'> => {
    return {
        renderCell: ({ value, field }) => {
            const metadata = iconMetadata[value];

            if (!metadata) {
                console.error(`Missing icon metadata for field '${field}'`);
                return null;
            };

            const id = (typeof metadata === 'string' ? metadata : metadata.id) as IconKey;

            const icon = ICON_INFO_MAP[id].icon;
            const tooltipText = (metadata as IconMetadata).tooltipText ?? ICON_INFO_MAP[id].tooltipText
    
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    {icon && <IconCell tooltipText={tooltipText} icon={icon} />}
                </Box>
            );
        }
    }
}