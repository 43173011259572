import { Tooltip } from "@mui/material";
import { useRef, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTypedTranslation } from "utils/customHooks";

interface LinkCellProps {
    text: string;
    path: string;
}

const LinkCell: FC<LinkCellProps> = ({ text, path }) => {
  const t = useTypedTranslation(["strGen"]);
  const location = useLocation();
  const cellRef = useRef<HTMLDivElement>(null);

  const isTruncated = (cellRef.current?.scrollWidth ?? 0) > (cellRef.current?.clientWidth ?? 0);

  return (
      <Tooltip
        title={
          isTruncated
            ? text
            : t("strGen:components.customcells.customlinkcell.tooltip")
        }
        placement="top"
        arrow
      >
        <Link to={`${location.pathname}/${path}`} style={{ textDecoration: "none", color: "blue" }}>
          <div
            ref={cellRef}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        </Link>
      </Tooltip>
  );
};

export default LinkCell;