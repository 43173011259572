import React from "react";
import { FormikProps } from "formik";
import { QuestionnaireFormValues } from "./BenefitsQuestionnaireTypes";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface HeadcountTableProps {
  formik: FormikProps<QuestionnaireFormValues>;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
  "&.MuiTableCell-root": {
    borderColor: theme.palette.divider,
  },
}));

const CategoryCell = styled(TableCell)(({ theme }) => ({
  borderRight: `2px solid ${theme.palette.divider}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.divider,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
    padding: theme.spacing(1),
  },
}));

const headcountCategories = [
  "Management",
  "Administration",
  "Engineering",
  "Technicians",
  "Professionals",
  "SkilledTrades",
  "Labour",
  "Students",
  "Other",
] as const;

const subLocations = ["NL", "OC", "NC"] as const;

const HeadcountTable: React.FC<HeadcountTableProps> = ({ formik }) => {
  const handleChange = (
    category: keyof QuestionnaireFormValues["Onshore"],
    location: "Onshore" | "Offshore",
    subLocation: keyof QuestionnaireFormValues["Onshore"]["Management"]["Male"],
    gender: keyof QuestionnaireFormValues["Onshore"]["Management"],
    value: number
  ) => {
    formik.setFieldValue(
      `${location}.${category}.${gender}.${subLocation}`,
      value
    );
  };

  const getValue = (
    category: keyof QuestionnaireFormValues["Onshore"],
    location: "Onshore" | "Offshore",
    subLocation: keyof QuestionnaireFormValues["Onshore"]["Management"]["Male"],
    gender: keyof QuestionnaireFormValues["Onshore"]["Management"]
  ) => {
    return (
      (formik.values[location] as any)?.[category]?.[gender]?.[subLocation] ?? 0
    );
  };

  const calculateCategoryTotal = (
    category: keyof QuestionnaireFormValues["Onshore"],
    location: "Onshore" | "Offshore",
    subLocation: keyof QuestionnaireFormValues["Onshore"]["Management"]["Male"]
  ) => {
    return (
      getValue(category, location, subLocation, "Male") +
      getValue(category, location, subLocation, "Female") +
      getValue(category, location, subLocation, "Other")
    );
  };

  const calculateSubLocationTotal = (
    location: "Onshore" | "Offshore",
    subLocation: keyof QuestionnaireFormValues["Onshore"]["Management"]["Male"]
  ) => {
    return headcountCategories.reduce(
      (sum, category) =>
        sum +
        calculateCategoryTotal(
          category as keyof QuestionnaireFormValues["Onshore"],
          location,
          subLocation
        ),
      0
    );
  };

  const calculateLocationTotal = (location: "Onshore" | "Offshore") => {
    return subLocations.reduce(
      (sum, subLocation) =>
        sum + calculateSubLocationTotal(location, subLocation),
      0
    );
  };

  const renderSubLocationTable = (
    location: "Onshore" | "Offshore",
    subLocation: "NL" | "OC" | "NC"
  ) => (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell
              rowSpan={2}
              sx={{ borderRight: "2px solid", borderColor: "divider" }}
            >
              Category
            </StyledTableCell>
            <StyledTableCell colSpan={3} align="center">
              Gender
            </StyledTableCell>
            <StyledTableCell rowSpan={2} align="center">
              Total
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center">Male</StyledTableCell>
            <StyledTableCell align="center">Female</StyledTableCell>
            <StyledTableCell align="center">Other</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {headcountCategories.map((category) => (
            <StyledTableRow key={category}>
              <CategoryCell>{category}</CategoryCell>
              {["Male", "Female", "Other"].map((gender) => (
                <TableCell key={gender} align="center">
                  <Typography>
                    {getValue(
                      category,
                      location,
                      subLocation,
                      gender as "Male" | "Female" | "Other"
                    )}
                  </Typography>
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                {calculateCategoryTotal(category, location, subLocation)}
              </TableCell>
            </StyledTableRow>
          ))}
          <TableRow>
            <StyledTableCell
              sx={{ borderRight: "2px solid", borderColor: "divider" }}
            >
              Total {subLocation}
            </StyledTableCell>
            <StyledTableCell />
            <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
              {calculateSubLocationTotal(location, subLocation)}
            </StyledTableCell>
            <StyledTableCell colSpan={2} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderLocationSection = (location: "Onshore" | "Offshore") => (
    <Grid item xs={12}>
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" gutterBottom color="primary">
          {location} Headcount
        </Typography>
        {subLocations.map((subLocation) => (
          <Accordion key={subLocation}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {subLocation === "NL"
                  ? "Newfoundland and Labrador"
                  : subLocation === "OC"
                  ? "Other Canadian"
                  : "Non-Canadian"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderSubLocationTable(location, subLocation)}
            </AccordionDetails>
          </Accordion>
        ))}
        <Box sx={{ mt: 2, textAlign: "right" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Total {location} Headcount: {calculateLocationTotal(location)}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3}>
        {renderLocationSection("Onshore")}
        {renderLocationSection("Offshore")}
      </Grid>
    </Box>
  );
};
export default HeadcountTable;
