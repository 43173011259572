import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActionPlan,
  CreateActionPlan,
  UpdateActionPlan,
  getActionPlans,
  getActionPlanByID,
  createActionPlan,
  updateActionPlan,
  deleteActionPlan,
} from "api/actions/actionPlanAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

interface ActionPlanState {
  actionPlansById: Record<number, ActionPlan>;
  actionPlanList: number[];
  isLoading: boolean;
  isLoadingEdit: boolean;
  error: string | null;
  touchedID: number | null; // Used to track the most recently created/updated Action Plan
}

const initialState: ActionPlanState = {
  actionPlansById: {},
  actionPlanList: [],
  isLoading: false,
  isLoadingEdit: false,
  error: null,
  touchedID: null,
};

function startLoading(state: ActionPlanState) {
  state.isLoading = true;
}

function startEditLoading(state: ActionPlanState) {
  state.isLoadingEdit = true;
}

function loadingFailed(state: ActionPlanState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.isLoadingEdit = false;
  state.error = action.payload;
}

const actionPlans = createSlice({
  name: "actionPlan",
  initialState,
  reducers: {
    getActionPlansStart: startLoading,
    getActionPlansSuccess(state, { payload }: PayloadAction<ActionPlan[]>) {
      state.isLoading = false;
      state.error = null;

      payload.forEach((actionPlan) => {
        state.actionPlansById[actionPlan.ActionPlanID] = actionPlan;
      });

      state.actionPlanList = payload.map(
        (actionPlan) => actionPlan.ActionPlanID
      );
    },
    getActionPlansFailure: loadingFailed,
    createActionPlanStart: startEditLoading,
    createActionPlanSuccess(state, { payload }: PayloadAction<ActionPlan>) {
      const { ActionPlanID } = payload;
      state.actionPlansById[ActionPlanID] = payload;
      state.actionPlanList = [...state.actionPlanList, ActionPlanID];

      state.touchedID = ActionPlanID;
      state.isLoadingEdit = false;
      state.error = null;
    },
    createActionPlanFailure: loadingFailed,
    updateActionPlanStart: startEditLoading,
    updateActionPlanSuccess(state, { payload }: PayloadAction<ActionPlan>) {
      const { ActionPlanID } = payload;
      state.actionPlansById[ActionPlanID] = payload;

      state.touchedID = ActionPlanID;
      state.isLoadingEdit = false;
      state.error = null;
    },
    updateActionPlanFailure: loadingFailed,
    deleteActionPlanStart: startEditLoading,
    deleteActionPlanSuccess(state, { payload }: PayloadAction<number>) {
      const ActionPlanID = payload;
      delete state.actionPlansById[ActionPlanID];
      state.actionPlanList = state.actionPlanList.filter(
        (id) => id !== ActionPlanID
      );

      state.isLoadingEdit = false;
      state.error = null;
    },
    deleteActionPlanFailure: loadingFailed,
    clearTouchedID(state) {
      state.touchedID = null;
    },
  },
});

export const {
  getActionPlansStart,
  getActionPlansSuccess,
  getActionPlansFailure,
  createActionPlanStart,
  createActionPlanSuccess,
  createActionPlanFailure,
  updateActionPlanStart,
  updateActionPlanSuccess,
  updateActionPlanFailure,
  deleteActionPlanStart,
  deleteActionPlanSuccess,
  deleteActionPlanFailure,
  clearTouchedID,
} = actionPlans.actions;

export default actionPlans.reducer;

// Thunks
export const fetchActionPlans =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getActionPlansStart());
      const actionPlans = await getActionPlans(accessToken);
      dispatch(getActionPlansSuccess(actionPlans));
    } catch (err: any) {
      dispatch(getActionPlansFailure(err.message));
    }
  };

export const fetchActionPlanByID =
  (accessToken: string, actionPlanID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getActionPlansStart());
      const actionPlan = await getActionPlanByID(accessToken, actionPlanID);
      dispatch(getActionPlansSuccess([actionPlan]));
    } catch (err: any) {
      dispatch(getActionPlansFailure(err.message));
    }
  };

export const addActionPlan =
  (
    accessToken: string,
    newActionPlan: CreateActionPlan & {
      actionPlanStages: {
        ActionPlanStageName: string;
        actionTemplateIDs: number[];
      }[];
    }
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionPlanStart());
      const actionPlan = await createActionPlan(accessToken, newActionPlan);
      dispatch(createActionPlanSuccess(actionPlan));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createActionPlanFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updActionPlan =
  (
    accessToken: string,
    actionPlanID: number,
    updateData: UpdateActionPlan & {
      actionPlanStages: {
        ActionPlanStageName: string;
        actionTemplateIDs: number[];
      }[];
    }
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateActionPlanStart());
      const actionPlan = await updateActionPlan(
        accessToken,
        actionPlanID,
        updateData
      );
      dispatch(updateActionPlanSuccess(actionPlan));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(updateActionPlanFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delActionPlan =
  (accessToken: string, actionPlanID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteActionPlanStart());
      await deleteActionPlan(accessToken, actionPlanID);
      dispatch(deleteActionPlanSuccess(actionPlanID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteActionPlanFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
