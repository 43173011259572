import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchAllBenefitsQuestionnaire } from "./benefitsQuestionnaireSlice";
import { useTranslation } from "react-i18next";

export const BenefitsQuestionnaireInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objBnf", "strGen"]);

  const {
    benefitsQuestionnaireList,
    benefitsQuestionnaireByID,
    isLoading,
    error,
  } = useSelector((state: RootState) => state.benefitsQuestionnaire);

  const benefitsQuestionnaires = benefitsQuestionnaireList.map(
    (id) => benefitsQuestionnaireByID[id]
  );

  const data = benefitsQuestionnaires.map((p) => {
    return {
      ...p,
      ReferenceNumber: p.ReferenceNumber ?? "N/A",
    };
  });

  const dataColumns = [
    {
      field: "ProponentName",
      title: "Proponent Name",
    },
    {
      field: "ContractManager",
      title: "Contract Manager",
    },
    {
      field: "ReferenceNumber",
      title: "Reference Number",
    },
    {
      field: "ProponentProvinceState",
      title: "Proponent Province/State",
    },
    {
      field: "Status",
      title: "Status",
      chips: true,
      columnWidth: 120,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAllBenefitsQuestionnaire(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (error) {
    return <ErrorPage errMsg={error} />;
  }

  return (
    <div id="benefits-questionnaire-inventory-page">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <IppInventory
          title="Benefits Questionnaire"
          tableData={data}
          idField="BenefitsQuestionnaireID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{ showAdd: false }}
          sortColumn={1}
          sortReverse={true}
          linkColumn={0}
          searchByColumn={"ProponentName"}
          searchByPlaceholder="Search by Proponent Name"
        />
      )}
    </div>
  );
};
