import { Grid, Box, Tooltip } from "@mui/material";
import { RenderDisplayField } from "../../components/Reusable Utils/RenderDisplayField";
import { IppFormDivider } from "../../components/IppFormDivider";
import { IppCheckbox } from "../../components/IppCheckbox";
import { FormikProps } from "formik";
import { QuestionnaireFormValues } from "./BenefitsQuestionnaireTypes";
import { getNumField, TotalFields, getNestedTotalField } from "./FormHelpers";
import { useRef, useEffect } from "react";

export interface NestedTotalField {
  id: string;
  FieldIds: string[];
}

// Helper function to safely get nested values
const getNestedValue = (path: string, obj: any) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const useNestedBatchTotalUpdater = (
  formik: FormikProps<any>,
  totalFields: NestedTotalField[]
) => {
  const prevValuesRef = useRef<{ [key: string]: number }>({});

  useEffect(() => {
    if (!formik.values) return;

    const updates: { [key: string]: number } = {};

    totalFields.forEach(({ id, FieldIds }) => {
      const total = FieldIds.reduce((sum, fieldId) => {
        const fieldValue = Number(getNestedValue(fieldId, formik.values)) || 0;
        return sum + fieldValue;
      }, 0);

      if (prevValuesRef.current[id] !== total) {
        updates[id] = total;
        prevValuesRef.current[id] = total;
      }
    });

    // Apply only changed values
    Object.entries(updates).forEach(([path, value]) => {
      formik.setFieldValue(path, value);
    });
  }, [formik.values, totalFields]);
};

interface StepProps {
  formik: FormikProps<QuestionnaireFormValues>;
  handleCustomChange?: (
    section: "Onshore" | "Offshore"
  ) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

interface HeadcountSectionProps extends StepProps {
  section: "Onshore" | "Offshore";
}

const HeadcountSection = ({
  formik,
  handleCustomChange,
  section,
}: HeadcountSectionProps) => {
  const categories = [
    "Management",
    "Administration",
    "Engineering",
    "Technicians",
    "Professionals",
    "SkilledTrades",
    "Labour",
    "Students",
    "Other",
  ];

  return (
    <Grid container spacing={2}>
      {categories.map((category) => (
        <Grid key={category} item xs={12}>
          <IppFormDivider title={category} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {getNumField({
                id: `${section}.${category}Male`,
                label: "# Of Male Employee(s)",
                formik,
                customChangeHandler: handleCustomChange?.(section),
              })}
            </Grid>
            <Grid item xs={6}>
              {getNumField({
                id: `${section}.${category}Female`,
                label: "# Of Female Employee(s)",
                formik,
                customChangeHandler: handleCustomChange?.(section),
              })}
            </Grid>
            <Grid item xs={6}>
              {getNumField({
                id: `${section}.${category}Other`,
                label: "# Of Other Gendered Employee(s)",
                formik,
                customChangeHandler: handleCustomChange?.(section),
              })}
            </Grid>
            <Grid item xs={6}>
              {getNestedTotalField({
                id: `${section}.${category}Total`,
                label: `Total ${category} Employees`,
                formik,
              })}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IppFormDivider title="Totals" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {getNestedTotalField({
              id: `${section}.TotalHeadcount`,
              label: "Total Headcount",
              formik,
            })}
          </Grid>
          <Grid item xs={6}>
            {getNumField({
              id: `${section}.TotalPersonHours`,
              label: "Total Person Hours",
              formik,
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const LegalAcknowledgments = ({ formik }: StepProps) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <IppFormDivider title={"Terms and Acknowledgments"} />
      <Box>
        <p>
          The Canada-Newfoundland and Labrador Atlantic Accord Implementation
          Act, and the Canada-Newfoundland and Labrador Atlantic Accord
          Implementation Newfoundland and Labrador Act (the Accord Acts) require
          that for applicable offshore oil and gas work or activity, a benefits
          plan must be approved by the Canada-Newfoundland and Labrador Offshore
          Petroleum Board (the C-NLOPB or the Board).
        </p>
        <p>
          Section 45 of the Accord Acts define a benefits plan as a plan for the
          employment of Canadians and, in particular, residents of Newfoundland
          and Labrador (NL), and for providing manufacturers, consultants,
          contractors and service companies in NL and other parts of Canada with
          a full and fair opportunity to participate on a competitive basis in
          the supply of goods and services used in any work or activity referred
          to in the benefits plan.
        </p>
        <p>
          Benefits Plans have been approved by the Board for the Hibernia
          Project (including the Hibernia Southern Extension or HSE) and the
          Hebron Project, as listed in the table attached as Schedule A, which
          lists amendments to Benefits Plans and Benefits Agreements between the
          project owners and the Province of Newfoundland and Labrador.
        </p>
        <p>
          The Hebron Benefits Plan references the Canada-Newfoundland and
          Labrador Benefits Plan Guidelines issued by the Board in February
          2006. The Board issued updated Canada-Newfoundland and Labrador
          Benefits Plan Guidelines in August 2022 which reflect the Board's
          current expectations. The applicable guidelines are referred to herein
          as the Benefits Plan Guidelines.
        </p>
        <p>
          Benefits requirements for the Hibernia Project and the Hebron Project
          are included in the applicable Benefits Plans (as amended) and
          Benefits Agreements listed in Schedule A and in Benefits Plan
          Guidelines, with the requirements for a particular project referred to
          herein as the Benefits Obligations.
        </p>
        <p>
          To ensure understanding and compliance, contractors are required to
          review (and ensure their sub-contractors and suppliers also review)
          the Benefits Obligations and applicable benefits information provided
          by the C-NLOPB.
        </p>
        <p>
          Contractors, sub-contractors and suppliers are subject to periodic
          industrial benefits audits undertaken by the C-NLOPB. These audits
          seek to verify that appropriate benefits-related procedures have been
          established and are being followed and that appropriate information is
          being collected and analyzed. As Operators of the projects, ExxonMobil
          Canada Properties and Hibernia Management and Development Company Ltd.
          (HMDC) may also periodically audit and assess compliance with the
          Benefits Obligations.
        </p>
        <p>
          The Proponent must complete all sections of this Benefits
          Questionnaire. The information provided will be used to ensure
          compliance with Benefits Obligations and will be included in any
          contract that may subsequently be concluded. The successful Proponent
          will also be required to report benefits information through a
          reporting tool selected by the Operator, which will be used to track
          and report benefits information to the Board.
        </p>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <IppCheckbox
          id="TermsAccepted"
          value={formik.values.TermsAccepted}
          onChangeFunction={(event: React.ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue("TermsAccepted", event.target.checked)
          }
          label="I accept the terms and conditions"
          isEditing={false}
          disabled={true}
        />
      </Box>
    </Grid>
  </Grid>
);

export const ProponentInfo = ({ formik }: StepProps) => (
  <Grid container spacing={2}>
    <IppFormDivider title={"Proponent Headquarters Information"} />
    <Grid item xs={6}>
      <RenderDisplayField
        key="ProponentName"
        label="Proponent Name"
        value={formik.values.ProponentName || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ProponentAddress"
        label="Proponent Address"
        value={formik.values.ProponentAddress || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ProponentCity"
        label="Proponent City"
        value={formik.values.ProponentCity || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ProponentProvinceState"
        label="Proponent Province/State"
        value={formik.values.ProponentProvinceState || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ProponentCountry"
        label="Proponent Country"
        value={formik.values.ProponentCountry || ""}
        fieldType="text"
      />
    </Grid>
    <IppFormDivider title={"Scope of Work Information"} />
    <Grid item xs={6}>
      <RenderDisplayField
        key="WorkScopeAddress"
        label="Work Scope Address"
        value={formik.values.WorkScopeAddress || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="WorkScopeCity"
        label="Work Scope City"
        value={formik.values.WorkScopeCity || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="WorkScopeProvinceState"
        label="Work Scope Province/State"
        value={formik.values.WorkScopeProvinceState || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="WorkScopeCountry"
        label="Work Scope Country"
        value={formik.values.WorkScopeCountry || ""}
        fieldType="text"
      />
    </Grid>
  </Grid>
);

export const SubContractorSupplier = ({ formik }: StepProps) => (
  <Grid container spacing={2}>
    <IppFormDivider
      title={
        "Sub-Contracts Expected to be Competitively Bid valued at $250,000 and above"
      }
    />
    <Grid item xs={6}>
      <RenderDisplayField
        key="ExpectedGoodsServices"
        label="Description of Goods/Services"
        value={formik.values.ExpectedGoodsServices || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ExpectedAnticipatedWorkLocation"
        label="Anticipated Location of Work Being Performed"
        value={formik.values.ExpectedAnticipatedWorkLocation || ""}
        fieldType="text"
      />
    </Grid>
    <IppFormDivider
      title={"Pre-existing Contracts valued at $250,000 and above"}
    />
    <Grid item xs={6}>
      <RenderDisplayField
        key="ExistingGoodsServices"
        label="Description of Goods/Services"
        value={formik.values.ExistingGoodsServices || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ExistingAnticipatedWorkLocation"
        label="Anticipated Location of Work Being Performed"
        value={formik.values.ExistingAnticipatedWorkLocation || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ExistingSubconSupName"
        label="Subcon Name"
        value={formik.values.ExistingSubconSupName || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="ExistingSubconSupLocation"
        label="Subcon Location"
        value={formik.values.ExistingSubconSupLocation || ""}
        fieldType="text"
      />
    </Grid>
    <Grid item xs={6}>
      <RenderDisplayField
        key="PercentNLandOC"
        label="NL OC Expenditure %"
        value={formik.values.PercentNLandOC || 0}
        fieldType="text"
      />
    </Grid>
  </Grid>
);

export const OnshoreHeadcountPersonEstimate = ({
  formik,
  handleCustomChange,
}: StepProps) => {
  const onshoreTotalFields = TotalFields.filter(
    (field) =>
      field.id.startsWith("Onshore.") || field.id === "Onshore.TotalHeadcount"
  );

  useNestedBatchTotalUpdater(formik, onshoreTotalFields);

  return (
    <HeadcountSection
      formik={formik}
      handleCustomChange={handleCustomChange}
      section="Onshore"
    />
  );
};

export const OffshoreHeadcountPersonEstimate = ({
  formik,
  handleCustomChange,
}: StepProps) => {
  const offshoreTotalFields = TotalFields.filter(
    (field) =>
      field.id.startsWith("Offshore.") || field.id === "Offshore.TotalHeadcount"
  );

  useNestedBatchTotalUpdater(formik, offshoreTotalFields);

  return (
    <HeadcountSection
      formik={formik}
      handleCustomChange={handleCustomChange}
      section="Offshore"
    />
  );
};

export const ExpenditureContentEstimate = ({ formik }: StepProps) => {
  useNestedBatchTotalUpdater(formik, TotalFields);

  const expenditureCategories = [
    { title: "Materials", fields: ["NL", "OC", "NC"] },
    { title: "Labour", fields: ["NL", "OC", "NC"] },
    { title: "Services", fields: ["NL", "OC", "NC"] },
    { title: "Transportation", fields: ["NL", "OC", "NC"] },
    { title: "Overheads", fields: ["NL", "OC", "NC"] },
  ];

  // Helper function to calculate total for a category
  const calculateCategoryTotal = (category: string) => {
    const nl =
      Number(formik.values[`${category}NL` as keyof QuestionnaireFormValues]) ||
      0;
    const oc =
      Number(formik.values[`${category}OC` as keyof QuestionnaireFormValues]) ||
      0;
    const nc =
      Number(formik.values[`${category}NC` as keyof QuestionnaireFormValues]) ||
      0;
    return nl + oc + nc;
  };

  // Helper function to calculate regional total
  const calculateRegionalTotal = (region: "NL" | "OC" | "NC") => {
    return expenditureCategories.reduce((sum, { title }) => {
      return (
        sum +
        (Number(
          formik.values[`${title}${region}` as keyof QuestionnaireFormValues]
        ) || 0)
      );
    }, 0);
  };

  // Helper function to calculate overall total
  const calculateOverallTotal = () => {
    return expenditureCategories.reduce((sum, { title }) => {
      return sum + calculateCategoryTotal(title);
    }, 0);
  };

  return (
    <Grid container spacing={2}>
      <IppFormDivider title={"Total Estimated Cost"} />
      <Grid item xs={12}>
        <RenderDisplayField
          key="TotalEstimatedCost"
          label="Total Estimated Cost"
          value={formik.values.TotalEstimatedCost || 0}
          fieldType="text"
        />
      </Grid>

      {expenditureCategories.map(({ title, fields }) => (
        <Grid key={title} item xs={12}>
          <IppFormDivider title={title} />
          <Grid container spacing={2}>
            {fields.map((field) => {
              const id = `${title}${field}`;
              const label = `${title} (${
                field === "NL"
                  ? "Newfoundland and Labrador"
                  : field === "OC"
                  ? "Other Canadian"
                  : "Non-Canadian"
              } %)`;

              return (
                <Grid key={id} item xs={6}>
                  <RenderDisplayField
                    key={id}
                    label={label}
                    value={
                      (formik.values[
                        id as keyof QuestionnaireFormValues
                      ] as number) ?? 0
                    }
                    fieldType="text"
                  />
                </Grid>
              );
            })}

            <Grid item xs={6}>
              <RenderDisplayField
                key={`Total${title}`}
                label={`${title} as % of Total Cost`}
                value={calculateCategoryTotal(title)}
                fieldType="text"
              />
            </Grid>
          </Grid>
        </Grid>
      ))}

      <IppFormDivider title={"Totals"} />
      <Grid item xs={6}>
        <RenderDisplayField
          key="TotalExpenditureNL"
          label="NL % Of Total Cost"
          value={calculateRegionalTotal("NL")}
          fieldType="text"
        />
      </Grid>
      <Grid item xs={6}>
        <RenderDisplayField
          key="TotalExpenditureOC"
          label="OC % Of Total Cost"
          value={calculateRegionalTotal("OC")}
          fieldType="text"
        />
      </Grid>
      <Grid item xs={6}>
        <RenderDisplayField
          key="TotalExpenditureNC"
          label="NC % Of Total Cost"
          value={calculateRegionalTotal("NC")}
          fieldType="text"
        />
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title="This field should show 100% after the above form is filled out completely"
          arrow
        >
          <Box>
            <RenderDisplayField
              key="TotalExpenditureOverall"
              label="Overall Total %"
              value={calculateOverallTotal()}
              fieldType="text"
            />
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const formatLabel = (field: string): string =>
  field
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/\b\w/g, (char) => char.toUpperCase());

export const BenefitsCommitments = ({ formik }: StepProps) => (
  <Grid container spacing={2}>
    {[
      "ProcurementStrategy",
      "Policies",
      "ResearchDevelopment",
      "TechTransfer",
    ].map((field) => (
      <Grid key={field} item xs={12}>
        <RenderDisplayField
          key={field}
          label={formatLabel(field)}
          value={formik.values[field as keyof QuestionnaireFormValues] as any}
          fieldType="text"
        />
      </Grid>
    ))}
  </Grid>
);
