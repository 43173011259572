import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActionTemplateResponse,
  createActionTemplate,
  deleteActionTemplate,
  getActionTemplateByID,
  getActionTemplates,
  ActionTemplateFormValues,
  updateActionTemplate,
} from "api/actions/actionTemplateAPI";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";

interface ActionTemplateState {
  actionTemplatesById: Record<number, ActionTemplateResponse>;
  actionTemplateList: number[];
  isLoading: boolean;
  isLoadingEdit: boolean;
  error: string | null;
  touchedID: number | null; // Used to immediately display the newly created/updated action template
}

const ActionTemplateInitialState: ActionTemplateState = {
  actionTemplatesById: {},
  actionTemplateList: [],
  isLoading: false,
  isLoadingEdit: false,
  error: null,
  touchedID: null,
};

function startLoading(state: ActionTemplateState) {
  state.isLoading = true;
}

function startEditLoading(state: ActionTemplateState) {
  state.isLoadingEdit = true;
}

function loadingFailed(
  state: ActionTemplateState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.isLoadingEdit = false;
  state.error = action.payload;
}

const actionTemplates = createSlice({
  name: "actionTemplate",
  initialState: ActionTemplateInitialState,
  reducers: {
    getActionTemplatesStart: startLoading,
    getActionTemplatesSuccess(
      state,
      { payload }: PayloadAction<ActionTemplateResponse[]>
    ) {
      const actionTemplates = payload;
      state.isLoading = false;
      state.error = null;

      actionTemplates.forEach((actionTemplate) => {
        state.actionTemplatesById[actionTemplate.ActionTemplateID] =
          actionTemplate;
      });

      state.actionTemplateList = actionTemplates.map(
        (actionTemplate) => actionTemplate.ActionTemplateID
      );
    },
    getActionTemplatesFailure: loadingFailed,
    createActionTemplateStart: startEditLoading,
    createActionTemplateSuccess(
      state,
      { payload }: PayloadAction<ActionTemplateResponse>
    ) {
      const { ActionTemplateID } = payload;
      state.actionTemplatesById[ActionTemplateID] = payload;
      state.actionTemplateList = [
        ...state.actionTemplateList,
        ActionTemplateID,
      ];

      state.touchedID = ActionTemplateID;
      state.isLoadingEdit = false;
      state.error = null;
    },
    createActionTemplateFailure: loadingFailed,
    updateActionTemplateStart: startEditLoading,
    updateActionTemplateSuccess(
      state,
      { payload }: PayloadAction<ActionTemplateResponse>
    ) {
      const { ActionTemplateID } = payload;
      state.actionTemplatesById[ActionTemplateID] = payload;

      state.touchedID = ActionTemplateID;
      state.isLoadingEdit = false;
      state.error = null;
    },
    updateActionTemplateFailure: loadingFailed,
    deleteActionTemplateStart: startEditLoading,
    deleteActionTemplateSuccess(state, { payload }: PayloadAction<number>) {
      const ActionTemplateID = payload;
      delete state.actionTemplatesById[ActionTemplateID];
      state.actionTemplateList = state.actionTemplateList.filter(
        (id) => id !== ActionTemplateID
      );

      state.isLoadingEdit = false;
      state.error = null;
    },
    deleteActionTemplateFailure: loadingFailed,
    clearTouchedID(state) {
      state.touchedID = null;
    },
  },
});

export const {
  getActionTemplatesStart,
  getActionTemplatesSuccess,
  getActionTemplatesFailure,
  createActionTemplateStart,
  createActionTemplateSuccess,
  createActionTemplateFailure,
  updateActionTemplateStart,
  updateActionTemplateSuccess,
  updateActionTemplateFailure,
  deleteActionTemplateStart,
  deleteActionTemplateSuccess,
  deleteActionTemplateFailure,
  clearTouchedID,
} = actionTemplates.actions;

export default actionTemplates.reducer;

export const fetchActionTemplates =
  (accessToken: string) => async (dispatch: any) => {
    try {
      dispatch(getActionTemplatesStart());
      const actionTemplates = await getActionTemplates(accessToken);
      dispatch(getActionTemplatesSuccess(actionTemplates));
    } catch (err: any) {
      dispatch(getActionTemplatesFailure(err.message));
    }
  };

export const fetchActionTemplateByID =
  (accessToken: string, actionTemplateID: number) => async (dispatch: any) => {
    try {
      dispatch(getActionTemplatesStart());
      const actionTemplate = await getActionTemplateByID(
        accessToken,
        actionTemplateID
      );
      dispatch(getActionTemplatesSuccess([actionTemplate]));
    } catch (err: any) {
      dispatch(getActionTemplatesFailure(err.message));
    }
  };

export const addActionTemplate =
  (accessToken: string, newActionTemplate: ActionTemplateFormValues) =>
  async (dispatch: any) => {
    try {
      dispatch(createActionTemplateStart());
      const actionTemplate = await createActionTemplate(
        accessToken,
        newActionTemplate
      );
      dispatch(createActionTemplateSuccess(actionTemplate));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createActionTemplateFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updActionTemplate =
  (
    accessToken: string,
    actionTemplateID: number,
    updateData: ActionTemplateFormValues
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(updateActionTemplateStart());
      const actionTemplate = await updateActionTemplate(
        accessToken,
        actionTemplateID,
        updateData
      );
      dispatch(updateActionTemplateSuccess(actionTemplate));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(updateActionTemplateFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delActionTemplate =
  (accessToken: string, actionTemplateID: number) => async (dispatch: any) => {
    try {
      dispatch(deleteActionTemplateStart());
      await deleteActionTemplate(accessToken, actionTemplateID);
      dispatch(deleteActionTemplateSuccess(actionTemplateID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(deleteActionTemplateFailure(err.message));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
