import { ColumnMetadata } from "slices/inventoryMetadata"

export const interationTypeIcons: ColumnMetadata["icon"] = {
    "Email": "interaction:email",
    "Phone Call": "interaction:phone_call",
    "Meeting (in person)": "interaction:meeting_in_person",
    "Meeting (virtual)": "interaction:meeting_virtual",
    "Regular Mail": "interaction:regular_mail",
    "Event": "interaction:event",
    "Text Message": "interaction:text_message",
    "Registered Mail": "interaction:registered_mail",
    "Web Platform": "interaction:web_platform",
    "Social Media": "interaction:social_media",
    "Focus Group": "interaction:focus_group",
    "Cultural Event": "interaction:cultural_event",
    "Informal Encounter": "interaction:informal_encounter",
    "Sponsorship": "interaction:sponsorship",
    "Undefined": "interaction:undefined"
}

export const sentimentIcons: ColumnMetadata["icon"] = {
    1: 'sentiment:very_dissatisfied',
    2: 'sentiment:dissatisfied',
    3: 'sentiment:neutral',
    4: 'sentiment:satisfied',
    5: 'sentiment:very_satisfied'
}

export const priorityIcons: ColumnMetadata["icon"] = {
    1: 'priority:low',
    2: 'priority:medium',
    3: 'priority:high'
}