import axiosClient from "../axiosClient";
import { Contact } from "./contactAPI";

export interface Interaction {
  InteractionID: number;
  InteractionTitle: string;
  InteractionTypeID: number;
  InteractionDate: Date;
  Details: string;
  ProjectID: number;
  ProjectCode: string;
  StatusID: number;
  ClientID: number;
  InteractionTypeName: string;
  StatusTypeName: string;
  ProjectName: string;
  PersonResponsibleName: string;
  CommunicationID: number;
  PersonResponsible: number;
  IsOverdue: boolean;
  Contacts: string;
  Groups: string;
  EmailContent: string;
  SentimentLevel: number;
  RelationshipOwner: number;
  InitiatedBy: string;
  LegalSubdivision: string;
  InternalNotes: string;
  NeedsReview: boolean;
  Internal: boolean;
  Tags: string;
}

export interface InteractionsResult {
  interactions: Interaction[];
}

export interface InteractionWithLists extends Interaction {
  ContactList: Contact[];
  GroupList: string[];
  IssueList: string[];
}

export async function getInteractions(
  accessToken: String
): Promise<InteractionsResult> {
  const url = `/interaction`;

  try {
    const { data } = await axiosClient.get<InteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionsForReview(
  accessToken: String
): Promise<InteractionsResult> {
  const url = `/interaction/review`;

  try {
    const { data } = await axiosClient.get<InteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionsByGroup(
  accessToken: String,
  groupID: number
): Promise<InteractionsResult> {
  const url = `/interaction/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<InteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionsByIssue(
  accessToken: String,
  issueID: number
): Promise<InteractionsResult> {
  const url = `/interaction/issue/${issueID}`;

  try {
    const { data } = await axiosClient.get<InteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getInteractionsByContact(
  accessToken: String,
  contactID: number
): Promise<InteractionsResult> {
  const url = `/interaction/contact/${contactID}`;

  try {
    const { data } = await axiosClient.get<InteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createInteraction(
  accessToken: String,
  newCommInv: Partial<Interaction>,
  contactList: any[],
  groupList: string[],
  issueList: string[],
  grievanceList: number[]
): Promise<Interaction> {
  const url = `/interaction`;

  try {
    const { data } = await axiosClient.post<Interaction>(
      url,
      {
        itemData: newCommInv,
        contactList: contactList,
        groupList: groupList,
        issueList: issueList,
        grievanceList: grievanceList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export const generateInteractionDetailsWithAi = async (
  accessToken: string,
  interaction: Partial<InteractionWithLists>
): Promise<string> => {
  const url = `/interaction/${interaction.InteractionID}/summarize`;

  try {
    const { data } = await axiosClient.post<string>(url, interaction, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
};

export async function updateInteraction(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<Interaction>,
  contactList: any[] | null,
  groupList: string[] | null,
  issueList: string[] | null,
  didEditParent: boolean
): Promise<Interaction> {
  const url = `/interaction/` + commInvID;

  try {
    const { data } = await axiosClient.post<Interaction>(
      url,
      {
        itemData: newCommInv,
        contactList: contactList,
        groupList: groupList,
        issueList: issueList,
        didEditParent,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteInteraction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/interaction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
