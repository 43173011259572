import { Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";

export interface IppQuestionnaireButtonProps {
  styling?: "centered" | "end" | "start";
  label?: string;
  onClick?: () => void; // Allow an external function to be passed
  icon?: any;
  color?: "primary" | "error"; // Update color prop to use "error" instead of "red"
  iconPosition?: "start" | "end"; // Add iconPosition prop
}

export const IppQuestionnaireButton = ({
  styling,
  label = "Submit",
  onClick,
  icon,
  color = "primary", // Default to primary
  iconPosition = "start", // Default to start
}: IppQuestionnaireButtonProps) => {
  const styles =
    styling === "centered"
      ? { display: "flex", justifyContent: "center", alignItems: "center" }
      : styling === "end"
      ? { display: "flex", justifyContent: "flex-end" }
      : { display: "flex", justifyContent: "flex-start" }; // Default to "start"

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(); // Trigger the passed function
    }
  };

  return (
    <Grid container spacing={2} paddingTop={2} sx={styles}>
      <Grid item>
        <Button
          color={color} // Use "error" directly instead of mapping
          variant="contained"
          startIcon={iconPosition === "start" ? icon : undefined}
          endIcon={iconPosition === "end" ? icon : undefined} // Add endIcon support
          type="button"
          onClick={handleClick} // Attach the click handler
        >
          {label}
        </Button>
      </Grid>
    </Grid>
  );
};
