import Inventory from "./Inventory";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchInventoryMetadata } from "slices";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { fetchInteractions } from "features/stakeholder/interaction/InteractionSlice";
import { Interaction } from "api/stakeholder/interactionAPI";
import { Commitment } from "api/commitments/commitmentAPI";
import { fetchCommitments } from "features/commitments/commitment/CommitmentSlice";
import { Button } from "@mui/material";
import { useHistory } from "react-router";

const InventoryTest = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { getAccessTokenSilently } = useAuth0();
    
    const {
        interactionList: list,
        interactionsById: idMap,
        isLoading: dataLoading,
        error: dataError,
      } = useSelector((state: RootState) => state.interactions);
      const inventoryId = 'engagement.communications'


    // const {
    //   commitmentList: list,
    //   commitmentsById: idMap,
    //   isLoading: dataLoading,
    //   error: dataError
    // } = useSelector((state: RootState) => state.commitments)
    // const inventoryId = 'commitments.commitments'
    
    const data = list.map((id) => idMap[id]);

    useEffect(() => {
        (async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(fetchInteractions(accessToken));
            // dispatch(fetchCommitments(accessToken))
            dispatch(fetchInventoryMetadata(accessToken, inventoryId));
          } catch (e) {
            console.error(e);
          }
        })();
      }, [dispatch, getAccessTokenSilently]);
    
    return (
        <div>
            <Inventory 
                inventoryId={inventoryId}
                data={data} 
                loading={dataLoading}
                title="Inventory Test"
                toolbar={<Button onClick={() => history.push('new')}>Add New</Button>}
            />
        </div>
    )
}

export default InventoryTest;