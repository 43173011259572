import { ReactElement, useCallback, useEffect } from "react";
import { DataGrid, type GridRowsProp, type GridColDef, GridToolbar, type GridColType, type GridRowIdGetter, type GridSlotsComponent, type GridSlotsComponentsProps, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchInventoryMetadata, ColumnMetadata, updateInventoryMetadata } from "slices/inventoryMetadata";
import { CommaDelimitedColumnDef, getIconColumnDef, getLinkCellColumnDef, getStatusChipColumnDef } from "./columnDefs";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material";

const slots: Partial<GridSlotsComponent> = { 
  toolbar: GridToolbar,
}

const slotProps: Partial<GridSlotsComponentsProps> = {
  loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
  toolbar: {
    showQuickFilter: true,
    sx: {
      margin: 1
    }
  }
}

interface InventoryProps {
    title: string;
    inventoryId: string;
    data: GridRowsProp;
    loading?: boolean;
    toolbar?: ReactElement
}

const Inventory = ({ title, inventoryId, data, loading, toolbar }: InventoryProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
      metadataByName
    } = useSelector((state: RootState) => state.inventoryMetadata);

  const { isLoading, error, metadata } = metadataByName[inventoryId] ?? {}

  const columns = Object.entries(metadata ?? {}).map(([key, metadata]: [string, ColumnMetadata]) => {
      const columnDef: GridColDef = {
        field: key,
        headerName: metadata.title,
        type: metadata.type as GridColType,
        width: metadata.width,
        flex: metadata.width ? undefined : 1,
        hideable: !metadata.primary,
        headerAlign: 'left',
        renderCell: ({ formattedValue }) => (
          <Tooltip title={formattedValue} placement="top" arrow>
            <span>{formattedValue}</span>
          </Tooltip>
        )
      }

      // Simple metadata types
      if (metadata.type === 'date') {
          columnDef.valueGetter = (date) => new Date(date)
      }

      if (metadata.format) {
          columnDef.valueFormatter = (value) => metadata.format?.replaceAll('{value}', value)
      }

      // Complext metadata types
      if (metadata.link) {
          return {
              ...columnDef,
              ...getLinkCellColumnDef(metadata.link)
          }
      }

      if (metadata.icon) {
        return {
          ...columnDef,
          ...getIconColumnDef(metadata.icon)
        }
      }

      if (metadata.statusChip) {
        return {
          ...columnDef,
          ...getStatusChipColumnDef(metadata.statusChip)
        }
      }

      if (metadata.commaDelimited) {
        return {
          ...columnDef,
          ...CommaDelimitedColumnDef
        }
      }

      return columnDef
    }) as GridColDef[];

    useEffect(() => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchInventoryMetadata(accessToken, inventoryId));
        } catch (e) {
          console.error(e);
        }
      })();
    }, [dispatch, getAccessTokenSilently]);

    const getRowId: GridRowIdGetter = useCallback((row) => {
      const found = Object.entries(metadata ?? {}).find(([_, value]: [string, ColumnMetadata]) => value.primary);
            
      if (!found) throw new Error(`'${inventoryId}' metadata is missing a 'primary' column`)

      return row[found[0]];
    }, [metadata])

    const onVisibilityChanged = (newModel: GridColumnVisibilityModel) => {
      const m = structuredClone(metadata)

      const visibilityChanges = Object.keys(newModel).reduce((acc, key) => {
        // @ts-ignore
        acc[key] = { ...m[key], visible: newModel[key] };
        return acc;
      }, {});

      dispatch(updateInventoryMetadata(inventoryId, visibilityChanges as ColumnMetadata))
    }

    return (
      <Box display="flex" flexDirection="column" >
        <Paper sx={{ borderBottom: 'none', borderRadius: '4px 4px 0 0' }}>
          <Grid container spacing={1} >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" component="h1" paddingLeft='1rem' paddingTop="1rem">
                  {title}
                </Typography>
              </Grid>
              <Grid item paddingTop="1rem" paddingRight="1rem">
                {toolbar}
              </Grid>
            </Grid>
          </Grid>
          <DataGrid 
            rows={data} 
            columns={columns}
            columnVisibilityModel={Object.keys(metadata ?? {}).reduce((acc, key) => {
              // @ts-ignore
              acc[key] = metadata[key].visible ?? true
        
              return acc
            }, {})}
            onColumnVisibilityModelChange={onVisibilityChanged}
            loading={isLoading || loading}
            getRowId={getRowId}
            slots={slots}
            slotProps={slotProps}
            sx={{
              backgroundColor: 'white',
              borderColor: 'rgb(0, 0, 0, 0.12)',
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: 'rgb(8, 1, 4, 0.063)',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: theme.palette.text.secondary
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: "rgb(0, 0, 0, 0.07)",
              },
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0 0 4px 4px',
            }}
          />
        </Paper>        
      </Box>
    )
}

export default Inventory;